import { geoPath, geoMercator } from 'd3-geo';
import { deviation } from 'd3-array';

import { getMatrixTransform, multiplyMatrixAndPoint } from './matrixUtils.js';

const modifiers = { width: 1.1, height: 0.9 };
const rotation = { x: -30, z: 50 };
const combinedMatrix = getMatrixTransform(rotation.x, rotation.z);

const maxGeometry = {
  type: "Feature",
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [ -180, 55 ], // top left
        [ 180, 55 ], // top right
        [ 180, -58.49227331726442 ], // bottom right
        [ -180, -58.49227331726442 ], // bottom left
      ],
    ],
  },
}; // approx continent bounds excluding the artic

function getBounds(path, geometry, isSweden = false) {
  const maxBounds = path.bounds(maxGeometry);
  const bounds = path.bounds(geometry),
    width = bounds[1][0] - bounds[0][0],
    y1 = isSweden ? bounds[0][1] : Math.max(bounds[0][1], maxBounds[0][1]),
    height = bounds[1][1] - y1,
    x = (bounds[0][0] + bounds[1][0]) / 2,
    y = (y1 + bounds[1][1]) / 2;
  return { width, height, x, y };
};

export const rotationMatrix = [
  [combinedMatrix[0][0], combinedMatrix[0][1]],
  [combinedMatrix[1][0], combinedMatrix[1][1]],
  [combinedMatrix[2][0], combinedMatrix[2][1]]
];

export function scaleProjection(geometry, width, height, scaleSettings) {
  const projection = geoMercator().scale(1).rotate([-11, 0]).translate([0, 0]);
  const path = geoPath().projection(projection);
  const target = getBounds(path, geometry);

  const s = 1 / Math.max(
   target.width / width * modifiers.width,
   target.height / height * modifiers.height,
  );
  const t = [(width - s * target.x) / 2, (height - s * target.y) / 2];

  projection.scale(s).translate(t);
  path.projection(projection);
  return { path, projection };
};

export function coordinateToScreen(coordinate, width, height) {
  const [ x, y ] = coordinate;
  const input = [ x - width / 2, y - height / 2 ];
  const point = multiplyMatrixAndPoint(combinedMatrix, input);
  const screen = [ point[0] + width / 2, point[1] + height / 2 ];
  return screen;
}

function filterFeatureCoordinates(feature) {
  const filteredCoordinates = [];
  const { coordinates } = feature.geometry;
  const coordinateLengths = coordinates.map(c => c[0].length).sort((a, b) => b - a);
  const minLength = deviation(coordinateLengths) || 0;

  if (minLength) {
    coordinates.forEach(c => {
      const [group] = c;
      if (group.length > minLength) {
        filteredCoordinates.push([group]);
      }
    });
    return {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: filteredCoordinates,
      },
    };
  } else {
    return feature;
  }
}

export function fitTransform(path, geometry, width, height, scaleSettings, scaleMultiplier = 1) {
  let target = geometry;
  const isSweden = geometry.features.length === 1 && geometry.features[0].id === '752';
  const isBrazil = geometry.features.length === 1 && geometry.features[0].id === '076';
  const shiftDown = isBrazil;
  if (geometry.filter && !isSweden) {
    const featureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    geometry.features.forEach(f => {
      const feature = filterFeatureCoordinates(f)
      featureCollection.features.push(feature);
    });
    target = featureCollection;
  }

  const bounds = getBounds(path, target, isSweden);

  const baseScale = (geometry.filter ? 0.68 : 0.86) * scaleMultiplier;
  const scale = Math.min(Math.max(
      baseScale / Math.max(
        bounds.width / width * modifiers.width,
        bounds.height / height * modifiers.height,
      )
    , scaleSettings.min), scaleSettings.max);

  const screen = coordinateToScreen([bounds.x, bounds.y], width, height);

  const x = width / 1.85 - screen[0] * scale;
  const y = shiftDown
    ? height / 3 * 2 - screen[1] * scale
    : height / 2 - screen[1] * scale;

  return { translation: { x, y }, scale };
}
