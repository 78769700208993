import { useEffect, useState } from 'react';
import { csvParse } from 'd3-dsv';
import dataFile from './data/ChinaLoansToAfrica_2024.csv';
export default function useChinaAfricaLoanData() {
  const [data, setData] = useState([]);

  useEffect(() => {
    window
      .fetch(dataFile)
      .then(response => response.text())
      .then(text => {
        const rows = csvParse(text);
        const numbers = ['USD (M)', 'Year'];
        const renames = {
          'USD (M)': 'amount',
        };
        rows.forEach(row => {
          numbers.forEach(number => {
            row[number] = +row[number];
          });
          Object.keys(renames).forEach(key => {
            row[renames[key]] = row[key];
          });
          row['Lender Type'] = row['Lender Type'].trim();
        });
        //console.log(rows)
        setData(rows);
      });
  }, []);

  return data;
}
