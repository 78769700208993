export const lenders = {
  CIDCA: '中国国家国际发展合作署',
  CHEXIM: '口行',
  'CHEXIM and other Lenders': '工行和其他贷款方',
  CDB: '国开行',
  'China Energy Engineering Corporation (CEEC)': '中国能源建设集团有限公司',
  ICBC: '工行',
  'ICBC and other Lenders': '工行和其他贷款方',
  'MixedCN: Industrial and Commercial Bank of China Ltd. (ICBC), Export-Import Bank of China (CHEXIM), Bank of China (BoC), China Construction Bank (CCB), China Minsheng Bank, Ping An Bank Co., Ltd.': '混合中国贷方：工行、口行、中银、建设银行、民生银行和平安银行',
  BoC: '中银',
  'MixedCN: ICBC & China Minsheng Bank': '混合中国贷方：工行和民生银行',
  'China National Aero-Technology Import and Export Corporation (CATIC)': '中航技进出口有限责任公司',
  'Huawei Technologies Co., Ltd.': '华为技术有限公司',
  BOC: '中银',
  'BoC and other Lenders': '中银和其他贷款方',
  'Zhongxing Telecommunication Equipment Corporation (ZTE)': '中兴通讯',
  'China Machinery Engineering Corporation (CMEC)': '中国机械设备工程股份有限公司',
  'CMEC (China Machinery Engineering Corporation)': '中国机械设备工程股份有限公司',
  Huawei: '华为技术有限公司',
  'China Merchants Port Holdings Company Limited (CMPort)': '招商局控股港口有限公司',
  'CN Gov': '中国中央政府',
  'MixedCN: CHEXIM & ICBC': '混合中国贷方：口行和工行',
  'MixedCN: CHEXIM, Industrial and Commercial Bank of China, Bank of China, Minsheng Bank, China CITIC Bank, Construction Bank, Kuwait National Bank, and HSBC': '混合中国贷方：口行、工行、中银、民生银行、中信银行、建设银行、科威特国家银行和汇丰银行',
  'State Grid Corporation of China (SGCC)': '国家电网公司',
  CCB: '中国建设银行',
  'China International Water & Electric Corporation (CWE)': '中国水利电力对外有限公司',
  'Poly Technologies Inc.': '保利科技有限公司',
  'Sinohydro Ltd.': '中国水利水电建设集团公司',
  'Dongfang Electric Corporation (DEC)': '中国东方电气集团有限公司',
  'China Shipbuilding Trading Company (CSTC)': '中国船舶工业贸易有限公司',
  'China Gezhouba Group Corporation (CGGC)': '中国葛洲坝集团股份有限公司',
  'Swakop Uranium (SU)': 'Swakop Uranium (SU)',
  'Alcatel-Lucent Shanghai Bell Co., Ltd.': '上海贝尔股份有限公司',
  'Shandong Electric Power Construction Corporation III (SEPCO III)': '山东电力建设第三工程公司',
  PBC: '中国人民银行',
  'Harbin Electric Company, Ltd.': '哈尔滨电气公司',
  'China National Machinery Industry Corporation (Sinomach)': '中国机械工业集团有限公司',
  'Tebian Electric Apparatus Co., Ltd. (TBEA)': '特变电工股份有限公司',
  CMEC: '中国机械设备工程股份有限公司 ',
  'China National Electric Engineering Co., Ltd. (CNEEC)': '中国电力工程有限公司',
  CITIC: '中信银行',
  'MixedCN: Export-Import Bank of China (CHEXIM), Industrial and Commercial Bank of China Ltd. (ICBC)': '混合中国贷方：口行和工行',
  'Aviation Industry Corporation of China (AVIC)': '中国航空工业集团公司',
  'Other CN Financing: Jiangxi Bank Co., Ltd.': '其他中国融资：江西银行股份有限公司',
  'StarTimes Group (StarTimes)': '四达时代集团公司',
  'China Nonferrous Metal Mining (Group) Co., Ltd. (CNMC) Industrial Zone Development': '中国有色矿业集团有限公司工业区开发',
  'China Geo Construction Engineering Corporation': '中国地质工程集团公司',
  'China Machinery Engineering Corporation': '中国机械设备工程股份有限公司 ',
  'China Jiangxi Corporation for International Economic and Technical Cooperation (CJIC)': '中国江西国际经济技术合作有限公司',
  'Kimberley (Chinese Private Company)': 'Kimberley (Chinese Private Company)',
  'Xuzhou Construction Machinery Group (XCMG)': '徐工集团',
  'CITIC Group Corporation Ltd. (CITIC)': '中信银行',
  'ZTE (Zhongxing Telecommunication Equipment Corporation)': '中兴通讯',
  'SGCC (State Grid Corporation of China)': '国家电网公司',
  'China Electric Power Equipment and Technology': '中国电力技术装备有限公司',
  'CWE (China International Water & Electric Corporation)' : '中国水利电力对外有限公司',
  'China National Building Materials & Equipment Import and Export Corporation (CBMIE)': '中建材集团进出口公司',
  'DEC (Dongfang Electric Corporation)': '中国东方电气集团有限公司',
  'Chinese Machinery Engineering Corporation (CMEC)': '中国机械设备工程股份有限公司',
  'China Geo Construction Engineering Corporation (CGC)': '中国地质工程集团公司',
  'SEPCO III (Shandong Electric Power Construction Corporation III)': '山东电力建设第三工程公司',
  'Kimberley (Chinese Private Company)': '金伯利（中国私营企业）',
  'Swakop Uranium (SU)': '斯瓦科普铀业有限公司',
  'CNPC (China National Petroleum Corporation)': '中国石油天然气集团有限公司',
  'CMPort (China Merchants Port Holdings Company Limited)': '招商局控股港口有限公司',
  'CEEC (China Energy Engineering Corporation)': '中国能源建设集团有限公司',
  'CATIC (China National Aero-Technology Import and Export Corporation)': '中航技进出口有限责任公司',
  'CGGC (China Gezhouba Group Corporation Ltd.)': '中国葛洲坝集团股份有限公司',
  'China CAMC Engineering Co Ltd (CAMCE)': '中工国际工程股份有限公司',
  "The People's Bank of China (PBC)": '中国人民银行',
};
