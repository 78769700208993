import Localization from '../Localization';

import logo from '../images/logo.png';
import twitter from '../images/twitter.png';
import facebook from '../images/facebook.png';
import './styles.scss';
export default function Header(props) {
  const { setAboutOpen, aboutOpen } = props;
  const link = window.location.href;

  const tweetText = `Explore the Chinese Loans to Africa Database via @GDP_Center: `;
  const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    tweetText
  )}&url=${encodeURIComponent(link)}`;

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
  return (
    <header className="header">
      <a href="https://www.bu.edu/gdp/">
        <img src={logo} className="header__logo" alt="logo" />
      </a>
      <h1>{Localization.get('Chinese Loans to Africa Database')}</h1>
      <div className="menu">
        <button onClick={() => setAboutOpen(!aboutOpen)}>
          {Localization.get('About / Data Download')}
        </button>
        <div className="section languageSelector">
          <div className="label">{`${Localization.get('language')}:`}</div>
          <div className="group">
            {Localization.getLanguages().map(lang => (
              <div
                key={lang.locale}
                className={`language ${lang.locale === props.language ? 'selected' : ''}`}
                onClick={() => props.updateLanguage(lang.locale)}
              >
                {lang.label}
              </div>
            ))}
          </div>
        </div>
        <div className="links">
          <a href={twitterURL} target="_blank" rel="noreferrer">
            <img src={twitter} alt="twitter" />
          </a>
          <a href={facebookUrl} target="_blank" rel="noreferrer">
            <img src={facebook} alt="facebook" />
          </a>
        </div>
      </div>
    </header>
  );
}
