export const measureMaxTextWidth = (textArr, fontSize, fontWeight) => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  // tempDiv.style.visibility = 'hidden';
  tempDiv.style.whiteSpace = 'nowrap';
  tempDiv.style.fontSize = fontSize;
  tempDiv.style.fontWeight = fontWeight;

  textArr.forEach(text => {
    const textDiv = document.createElement('div');
    textDiv.innerHTML = text;
    tempDiv.appendChild(textDiv);
  });
  document.body.appendChild(tempDiv);
  const width = tempDiv.clientWidth;
  document.body.removeChild(tempDiv);
  return width;
};

export const shortenCategoryText = text => {
  return text === 'Information and Communication Technology' ? 'ICT' : text;
};
