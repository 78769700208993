function degreeToRadian(degree) {
  return degree * (Math.PI / 180);
}

function multiplyMatrices(m1, m2) {
  var result = [];
  for (var i = 0; i < m1.length; i++) {
      result[i] = [];
      for (var j = 0; j < m2[0].length; j++) {
          var sum = 0;
          for (var k = 0; k < m1[0].length; k++) {
              sum += m1[i][k] * m2[k][j];
          }
          result[i][j] = sum;
      }
  }
  return result;
}

export function getMatrixTransform(zDegreeRotation, xDegreeRotation) {
  const radianRotation = {
    z: degreeToRadian(zDegreeRotation),
    x: degreeToRadian(xDegreeRotation),
  };
  const zMatrix = [
    [Math.cos(radianRotation.z), Math.sin(radianRotation.z), 0],
    [-Math.sin(radianRotation.z), Math.cos(radianRotation.z), 0],
    [0, 0, 1]
  ];
  const xMatrix = [
    [1, 0, 0],
    [0, Math.cos(radianRotation.x), Math.sin(radianRotation.x)],
    [0, -Math.sin(radianRotation.x), Math.cos(radianRotation.x)]
  ];
  return multiplyMatrices(zMatrix, xMatrix);
}

export function multiplyMatrixAndPoint(matrix, point) {
  const [x, y] = point;
  const tx = (x * matrix[0][0]) + (y * matrix[1][0]);
  const ty = (x * matrix[0][1]) + (y * matrix[1][1]);
  return [tx, ty];
}
