export const countries = {
  Angola: '安哥拉',
  'Burkina Faso': '布基纳法索',
  Burundi: '布隆迪',
  Benin: '贝宁',
  Botswana: '博茨瓦纳',
  'Congo, Democratic Republic of the': '刚果民主共和国',
  'Central African Republic': '中非共和国',
  'Congo, Republic of the': '刚果共和国',
  'Côte d’Ivoire': '象牙海岸',
  Cameroon: '喀麦隆',
  'Cabo Verde': '佛得角',
  Djibouti: '吉布提',
  Algeria: '阿尔及利亚',
  Egypt: '埃及',
  Eritrea: '厄立特里亚',
  Eswatini: '斯威士兰',
  Ethiopia: '埃塞俄比亚',
  Gabon: '加蓬',
  'Gambia': '冈比亚',
  Ghana: '加纳',
  Guinea: '几内亚',
  'Equatorial Guinea': '赤道几内亚',
  Kenya: '肯尼亚',
  Comoros: '科摩罗',
  Liberia: '利比里亚',
  Libya: '利比亚',
  Lesotho: '莱索托',
  Morocco: '摩洛哥',
  Madagascar: '马达加斯加',
  Mali: '马里',
  Mauritania: '毛里塔尼亚',
  Mauritius: '毛里求斯',
  Malawi: '马拉维',
  Mozambique: '莫桑比克',
  Namibia: '纳米比亚',
  Niger: '尼日尔',
  Nigeria: '尼日利亚',
  Regional: '区域',
  Rwanda: '卢旺达',
  Seychelles: '塞舌尔',
  Sudan: '苏丹',
  'Sierra Leone': '塞拉利昂',
  Senegal: '塞内加尔',
  'South Sudan': '南苏丹',
  Chad: '乍得',
  Somalia: '索马里',
  Togo: '多哥',
  Tunisia: '突尼斯',
  Tanzania: '坦桑尼亚',
  Uganda: '乌干达',
  'South Africa': '南非',
  'W. Sahara': '西撒哈拉',
  Zambia: '赞比亚',
  Zimbabwe: '津巴布韦',
};
