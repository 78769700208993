import { countries } from './zh_countries.js';
import { lenders } from './zh_lenders.js';
import { lenderTypes } from './zh_lenderTypes.js';
import { sectors } from './zh_sectors.js';

export const zhObj = {
  'About / Data Download': '关于 数据下载',
  'Chinese Loans to Africa Database': '中国对非洲贷款数据库',
  language: '语言',
  title: '中国对非洲贷款数据库',
  intro1:
    '中国对非洲贷款（CLA）数据库是一个交互数据项目，追踪中国开发性金融机构(DFIs)、商业银行、政府实体和企业向非洲政府、国有企业和区域性机构提供的贷款承诺。',
  intro2:
    '自2007以来, 研究人员收集、整理并分析了相关公开数据，创建了中国对非洲贷款数据库。数据来源包括政府官方文件、承包商网站、实地调查、访谈和媒体来源。',
  intro3: '截至2021年3月29日, 中国对非洲贷款数据库由波士顿大学全球发展政策研究中心运营。',
  intro4:
    '中国对非洲贷款数据库估算在2000到2023年间，41家中国贷款机构向49个非洲政府和7家区域性机构提供了总计1306笔贷款承诺，总额达1822.8亿美元。该数据库中的贷款金额并不等同于非洲政府债务，因为数据库跟踪的是贷款承诺，而不是实际支付、偿还或违约情况。',
  exploreData: '探索数据',
  scrollToContinue: '下拉页面继续',
  about: '关于',
  dataDownload: '数据下载',
  africa: '非洲',
  amount: '金额',
  noOfLoans: '贷款数量',
  numOfLoans: '贷款数量',
  regionalLoans: '区域性贷款',
  regionalLoansInfo:
    '区域性贷款是指向非洲区域性机构提供的贷款，比如非洲进出口银行(Afreximbank)、非洲金融公司(AFC)、中部非洲国家发展银行(BDEAC)、西非发展银行(BOAD)、贸易和发展银行(TDB)、塞内加尔河流域开发组织(OMVS)和阿拉伯国际银行(AIB)。',
  committedAmountPerCountry: '每个国家的承诺金额 ($)',
  type: '类型',
  sectors: '行业',
  lenders: '贷方',
  lender: '贷方',
  unit: '单元',
  country: '国家',
  project: '项目',
  years: '年份',
  year: '年份',
  clickExploreText: value => `点击探索 "${value}" 的数据`,
  selectOneOrMoreSectorsToUpdate: '选中一个或多个行业以更新地图显示',
  selectOneOrMoreYearsToUpdate: '选中一个或多个年份以更新地图显示',
  backToIntro: '返回介绍',
  amountCommitted: '承诺金额',
  dollarCommitted: '承诺金额（$)',
  dollarAllocation: '分配金额（$)',
  mapIntroOverlay: '在地图上选择一个或多个国家，按年份、行业和贷方类型浏览项目和贷款',
  energy: '能源业',
  transport: '交通业',
  ict: '信息与通信技术',
  industryTradeServices: '工业、贸易和服务业',
  other: '其他',
  waterSanitationWaste: '水利/卫生/废物处理',
  publicAdmin: '公共管理业',
  defenseMilitary: '国防和军事',
  agriculture: '农业',
  education: '教育业',
  health: '医疗业',
  environment: '环境',
  nonEnergyMining: '非能源采矿业',
  socialProtection: '社会保障业',
  expandAll: '显示全部',
  expandAllLists: '显示全部列表',
  collapseAll: '拆叠全部',
  collapseAllLists: '拆叠全部列表',
  clickToExpandCollapseGroup: '点击显示/折叠集团',
  clickToExpandCollapseCountry: '点击显示/折叠国家',
  resetMap: '重置地图',
  noProjectsInCountry: value => `在 ${value} 没有项目`,
  multipleCountries: '多个国家',
  lists: '列表',
  resetTypes: '重置类型',
  resetYears: '重置年份',
  clickToSelectAndUpdateMapDisplay: value => `点击选择 "${value}" 并更新地图显示`,
  policyBanks: '中国的开发性金融机构',
  chexim: '中国进出口银行',
  cdb: '中国国家开发银行',
  otherCNGov: '其他中国政府实体',
  cidca: '中国国家国际发展合作署',
  pbc: <>中国人民银行</>,
  cnGov: '其他中国',
  commericalBanks: '商业银行',
  icbc: '中国工商银行',
  ccb: '中国建设银行',
  abc: '中国农业银行',
  citic: '中国中信银行',
  boc: '中国银行',
  contractors: '承包商',
  parentCompany: '母公司或子公司',
  otherCN: '其他中国',
  mixedCN: '包括中国政府实体、中国商业银行和/或中国承包商在内的银团贷款。所有参与方须为中国实体。',
  ambiguousLenders: '不明确的贷款机构，如中国国际基金或非洲共同成长基金。',
  ...countries,
  Africa: '非洲',
  'Other Countries': '其他国家',
  ...lenders,
  ...lenderTypes,
  ...sectors,
  amountOfAfrica: '$ 非洲金额',
  noloa: '非洲贷款数量',
  loan: '贷款',
  loans: '贷款',
  acronym: '首字母缩写',
  ucge: '未知中国政府实体',
  pcosc: '母公司或子公司',
  companyName: '公司名称',
  'MixedCN:': '银团贷款:',
  'Other CN financing:': '其他中国融资:',
  leanAmount: '笔贷款',
};
