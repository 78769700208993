import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { MapInteraction } from 'react-map-interaction';
import { feature, merge } from 'topojson';
import { line, curveLinearClosed } from 'd3-shape';
import sortBy from 'lodash.sortby';
import { group, sum, extent, max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { MapGradients } from '../Gradients.js';
import { getCountryById } from '../countryLookup.js';
import {
  scaleProjection,
  fitTransform,
  coordinateToScreen,
  rotationMatrix,
} from './projectionUtils.js';
import { geoCentroid } from 'd3-geo';
import countries from './countries-50m.json';
import disputed from './disputed.topo.json';
import classNames from 'classnames';
import colors from '../colors';
import './styles.scss';
import Localization from '../Localization';
import { moneyFormatter } from '../Table';
import regionalAfricaIcon from '../images/regionalAfricaIcon.png';
import infoIcon from '../images/info_icon.svg';
import ReactTooltip from 'react-tooltip';
import { measureMaxTextWidth, shortenCategoryText } from '../utils.js';
window.countries = countries;
const noop = () => () => {};
countries.objects.countries.geometries = countries.objects.countries.geometries
  .filter(c => c.id !== '010')
  .map((c, i) => {
    c.id = c.id || `?-${i}`;
    return c;
  });

const circleCentroid = [46, 22];

/*
Countries missing IDs in countries geometry (c.id === undefined):
  "Somaliland"
  ​"Kosovo"
  ​"N. Cyprus"
  ​"Indian Ocean Ter."
  ​"Ashmore and Cartier Is."
  ​"Siachen Glacier"
*/
const africaCountryList = [
  'Nigeria',
  'Ethiopia',
  'Egypt',
  'Congo, Democratic Republic of the',
  'Tanzania',
  'South Africa',
  'Kenya',
  'Uganda',
  'Algeria',
  'Sudan',
  'Morocco',
  'Angola',
  'Mozambique',
  'Ghana',
  'Madagascar',
  'Cameroon',
  'Côte d’Ivoire',
  'Niger',
  'Burkina Faso',
  'Mali',
  'Malawi',
  'Zambia',
  'Senegal',
  'Chad',
  'Somalia',
  'Zimbabwe',
  'Guinea',
  'Rwanda',
  'Benin',
  'Burundi',
  'Tunisia',
  'South Sudan',
  'Togo',
  'Sierra Leone',
  'Libya',
  'Congo, Republic of the',
  'Liberia',
  'Central African Republic',
  'Mauritania',
  'Eritrea',
  'Namibia',
  'Gambia',
  'Botswana',
  'Gabon',
  'Lesotho',
  'Guinea-Bissau',
  'Equatorial Guinea',
  'Mauritius',
  'Eswatini',
  'Djibouti',
  'Comoros',
  'Cabo Verde',
  'São Tomé and Principe',
  'Seychelles',
  'W. Sahara',
  'Regional',
];

africaCountryList.forEach(country => {
  const exists = countries.objects.countries.geometries.find(c => c.properties.name === country);
  if (!exists) {
    //console.log(country, 'missing');
  }
});
const disputedFeatures = feature(disputed, disputed.objects['disputed.geo']);
const africanCountryTopos = countries.objects.countries.geometries.filter(c =>
  africaCountryList.includes(c.properties.name)
);
const africaGeojsonOutline = merge(countries, africanCountryTopos);
const africaGeojsonFeatures = feature(countries, countries.objects.countries);
africaGeojsonFeatures.features = africaGeojsonFeatures.features.filter(c =>
  africaCountryList.includes(c.properties.name)
);
africaGeojsonFeatures.features.forEach(feature => {
  // find feature center point
  const centroid = geoCentroid(feature);
  feature.properties.centroid = centroid;

  // manually adjust center points for some countries
  if (feature.properties.name === 'Congo, Democratic Republic of the') {
    let shiftAmount = 1.5;
    feature.properties.centroid[0] += shiftAmount;
    feature.properties.centroid[1] -= shiftAmount;
  } else if (feature.properties.name === 'Morocco') {
    let shiftAmount = 2;
    feature.properties.centroid[0] += shiftAmount;
    feature.properties.centroid[1] += shiftAmount;
  } else if (feature.properties.name === 'Senegal') {
    feature.properties.centroid[1] += 1.2;
  } else if (feature.properties.name === 'Ethiopia') {
    feature.properties.centroid[0] -= 0.75;
  } else if (feature.properties.name === 'Guinea') {
    feature.properties.centroid[0] -= 1.5;
    feature.properties.centroid[1] += 0.4;
  } else if (feature.properties.name === 'South Sudan') {
    feature.properties.centroid[0] += 0.4;
  } else if (feature.properties.name === 'Cameroon') {
    feature.properties.centroid[0] += 0.4;
    feature.properties.centroid[1] += 0.4;
  }
});

const settings = {
  scale: {
    min: 1,
    max: 21,
  },
  barSize: 12,
};

const isoBar = line()
  .x(d => d.x)
  .y(d => d.y)
  .curve(curveLinearClosed);

function generateIsoBar(width, height, yOffset, cap) {
  const pos = {
    x: 0,
    y: -(height + yOffset),
  };
  const iso = {
    x: width * 0.14,
    y: width * 0.44,
  };
  let capOffset = 0;
  if (cap) {
    capOffset = -(iso.y / 2);
  }
  const hexagon = [
    { x: pos.x + -iso.x, y: pos.y + height + iso.y + capOffset }, // bottom point
    { x: pos.x + width / 2, y: pos.y + height + iso.y / 2 + capOffset }, // bottom right
    { x: pos.x + width / 2, y: pos.y }, // top right
    { x: pos.x + iso.x, y: pos.y + -iso.y }, // top point
    { x: pos.x + -width / 2, y: pos.y - iso.y / 2 }, // top left
    { x: pos.x + -width / 2, y: pos.y + height + capOffset }, // bottom left
    { x: pos.x + -iso.x, y: pos.y + height + iso.y + capOffset }, // bottom point
  ];
  return isoBar(hexagon);
}

export default function Map(props) {
  // const { transition, setTransition, yScale, width, height, geoSelection, noTransform, updateGeoSelection, fillSelection, stacks, geoType, updateGeoType, setTooltipData } = props;
  const {
    width,
    height,
    paddingTop,
    inIntro,
    introStep,
    chinaAfricaLoansData,
    clickCountry,
    typeAccessor,
    selectedCountries,
    type,
    setInIntro,
    allData,
    barScaledHeight,
  } = props;
  let { showWorld, highlightAfrica, highlightChina, colorLoans, maxColor, showBars } = introStep;
  if (!inIntro) {
    showWorld = false;
    highlightAfrica = false;
    highlightChina = false;
    colorLoans = true;
    showBars = true;
  }
  // console.log(showWorld, highlightAfrica, highlightChina, colorLoans, showBars)
  if (!maxColor) {
    maxColor = '#707070';
  }

  const [loansGroupedByCountryMap, loansGroupedByCountryArray, , allLoansGroupedByCountryArray] =
    useMemo(() => {
      const groupLoans = _loans => {
        const byCountry = Array.from(group(_loans, d => d.Country));
        // console.log(byCountry)

        byCountry.forEach(([country, loans]) => {
          if (!africaCountryList.includes(country)) {
            if (country.trim() === '') {
              console.warn('empty country', country, loans);
            }
            console.log(country, 'not found');
          }
        });
        const byCountryMap = {};
        const byCountryArray = [];

        byCountry.forEach(([country, loans]) => {
          const total = sum(loans, d => d.amount);
          const object = {
            country,
            loans,
            total,
          };
          byCountryMap[country] = object;
          byCountryArray.push(object);
        });
        return [byCountryMap, byCountryArray];
      };
      return [...groupLoans(chinaAfricaLoansData), ...groupLoans(allData)];
    }, [chinaAfricaLoansData, allData]);

  const amountExtent = useMemo(() => {
    return [0, max(loansGroupedByCountryArray, d => d.total)];
  }, [loansGroupedByCountryArray]);
  const countExtent = useMemo(() => {
    return extent(allLoansGroupedByCountryArray, d => d.loans.length);
  }, [allLoansGroupedByCountryArray]);

  const countryColorScale = useMemo(() => {
    const scale = scaleLinear().domain(amountExtent).range(['#f7f7f7', maxColor]);
    return scale;
  }, [amountExtent, maxColor]);

  const yScale = useMemo(() => {
    return scaleLinear().domain([0, countExtent[1]]).range([0, 230]);
  }, [countExtent]);

  const transition = false;

  const map = useMemo(() => {
    const map = {
      countries: feature(countries, countries.objects.countries),

      outline: merge(countries, countries.objects.countries.geometries),
    };
    const wSahara = map.countries.features.find(d => d.properties.name === 'W. Sahara');
    wSahara.geometry = disputedFeatures.features[0].geometry;
    return map;
  }, []);

  const projection = useMemo(
    () => scaleProjection(map.countries, width, height, settings.scale),
    [map.countries, width, height]
  );

  const africaZoomedOutTransform = useMemo(() => {
    const t = fitTransform(
      projection.path,
      africaGeojsonFeatures,
      width,
      height,
      settings.scale,
      0.75
    );
    return t;
  }, [projection.path, width, height]);

  const africaTransform = useMemo(() => {
    let scale = 1.5;
    if (width / height < 1.25) {
      scale = 1.2;
    }
    const t = fitTransform(
      projection.path,
      africaGeojsonFeatures,
      width,
      height,
      settings.scale,
      scale
    );
    t.translation.y += 100;
    return t;
  }, [projection.path, width, height]);

  const africaZoomedTransform = useMemo(() => {
    const t = fitTransform(
      projection.path,
      africaGeojsonFeatures,
      width,
      height,
      settings.scale,
      2
    );
    t.translation.y += 100;
    return t;
  }, [projection.path, width, height]);

  const defaultTransform = useMemo(() => {
    const t = fitTransform(projection.path, map.countries, width, height, settings.scale);
    t.translation.y = 100;
    return t;
  }, [projection.path, map.countries, width, height]);

  const [transform, setTransform] = useState(defaultTransform);

  useEffect(() => {
    if (!inIntro) {
      setTransform(africaZoomedTransform);
    } else if (!showWorld) {
      setTransform(africaTransform);
    } else if (highlightChina) {
      setTransform(defaultTransform);
    } else if (highlightAfrica) {
      setTransform(africaZoomedOutTransform);
    }
  }, [
    showWorld,
    highlightChina,
    highlightAfrica,
    inIntro,
    defaultTransform,
    africaTransform,
    africaZoomedOutTransform,
    africaZoomedTransform,
  ]);
  const updateTransform = useCallback(
    transform => {
      setTransform(transform);
      // console.log(transform)
    },
    [setTransform]
  );
  const resetMapTransition = useMemo(
    () =>
      function () {
        updateTransform(defaultTransform);
      },
    [defaultTransform, updateTransform]
  );

  const paths = useMemo(() => {
    const disputedLine = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [-13.183764537835009, 27.671165714764776],
          [-8.66841680582418, 27.666929572715734],
        ],
      },
    };
    const paths = {
      countries: map.countries.features.map(c => {
        c.country = getCountryById(c.id) || { name: 'c-?' };
        c.d = projection.path(c);

        return c;
      }),

      outline: projection.path(map.outline),
      africa: projection.path(africaGeojsonOutline),

      disputedLine: projection.path(disputedLine),
    };
    return paths;
  }, [map, projection]);
  paths.countries = useMemo(
    () =>
      paths.countries.map(c => {
        if (!showWorld) {
          // console.log(c)
          if (!africaCountryList.includes(c.properties.name)) {
            c.fill = 'transparent';
            c.stroke = 'transparent';
            return c;
          }
        }
        c.stroke = '#CFCFCF';
        if (c.properties.name === 'China' && highlightChina) {
          c.stroke = '#CC0000';
        }
        c.fill = '#fff';
        if (inIntro && colorLoans) {
          c.stroke = 'rgba(204, 0, 0, 0.25)';
        }
        c.fillOpacity = null;
        c.hasData = allData.some(d => d.Country === c.properties.name);
        if (colorLoans) {
          const countryDatum = loansGroupedByCountryMap[c.properties.name];
          if (countryDatum) {
            c.fill = countryColorScale(countryDatum.total);
          }
        }

        return c;
      }),
    [
      showWorld,
      highlightChina,
      paths.countries,
      loansGroupedByCountryMap,
      colorLoans,
      countryColorScale,
      inIntro,
      allData,
    ]
  );

  const africaOutlineColor = highlightAfrica ? '#CC0000' : showWorld ? 'transparent' : '#CCCCCC';
  const africaOutlineStrokeWidth = highlightAfrica ? '1' : '0';
  const showWorldOutline = showWorld;

  const bars = useMemo(() => {
    const regionalFeature = {
      properties: {
        name: 'Regional',
        centroid: circleCentroid,
      },
      id: 'Regional',
    };
    return [...africaGeojsonFeatures.features, regionalFeature]
      .map(feature => {
        if (!showBars) {
          return null;
        }
        const stack = {
          transform: projection.projection(feature.properties.centroid),
          name: feature.properties.name,
        };
        let yOffset = 0;
        let countryDatum = loansGroupedByCountryMap[feature.properties.name];
        if (feature.properties.name === 'Regional' && !countryDatum) {
          countryDatum = {
            loans: [],
          };
        }
        if (!countryDatum) {
          return null;
        }

        const barHeight = yScale(countryDatum.loans.length);
        stack.bars = [];
        if (inIntro) {
          stack.bars = [
            {
              path: generateIsoBar(settings.barSize, barHeight, 0, false),
              height: barHeight,
              yOffset: 0,
              fill: 'url(#fillGradient)',
            },
            {
              path: generateIsoBar(settings.barSize, 0, barHeight, true),
              height: 0,
              yOffset: barHeight,
              fill: '#FFA8AD',
            },
          ];
        } else {
          const byType = Array.from(group(countryDatum.loans, typeAccessor))
            .map(d => ({
              type: d[0],
              loans: d[1],
            }))
            .sort((a, b) => b.loans.length - a.loans.length);

          stack.bars = byType.map(group => {
            const barHeight = yScale(group.loans.length);
            const colorID = group.type.replace(/ |\(|\)/g, '-');

            const bar = {
              path: generateIsoBar(settings.barSize, barHeight, yOffset, false),
              height: barHeight,
              yOffset,
              // fill: colors[group.type]
              fill: `url(#${colorID})`,
            };
            yOffset += barHeight;
            return bar;
          });
          if (stack.bars.length) {
            stack.bars.push({
              path: generateIsoBar(settings.barSize, 0, barHeight, true),
              height: 0,
              yOffset: barHeight,
              fill: colors[byType[byType.length - 1].type],
            });
          }
        }
        stack.feature = feature;

        stack.shadowPath = generateIsoBar(settings.barSize, barHeight, 0, true);

        return stack;
      })
      .filter(d => !!d);
  }, [yScale, projection, showBars, inIntro, loansGroupedByCountryMap, typeAccessor]);

  const [countryBars, regionalBars] = useMemo(() => {
    return [
      bars.filter(d => d && d.name !== 'Regional'),
      bars.filter(d => d && d.name === 'Regional'),
    ];
  }, [bars]);

  let colorScaleLegend = null;
  if (props.colorScale) {
    let colorScaleTicks = props.colorScale.ticks();
    if (colorScaleTicks.length) {
      colorScaleTicks = [0].concat(colorScaleTicks);
    }
    colorScaleLegend = (
      <div className="colorScale">
        {colorScaleTicks.map((tick, tickIndex) => {
          if (tickIndex !== 0 && tickIndex !== colorScaleTicks.length - 1 && tickIndex % 2 === 0) {
            return null;
          }
          return (
            <div className="tick" key={tick}>
              <div
                className="tickFill"
                style={{ backgroundColor: tick ? props.colorScale(tick) : '#fff' }}
              />
              <div className="tickLabel">{Math.round(tick / 1000)}k MW</div>
            </div>
          );
        })}
      </div>
    );
  }

  const mapRef = useRef();
  const [hoveredCountry, setHoveredCountry] = useState({
    opacity: 0,
    x: 0,
    y: 0,
    country: null,
  });
  const hoverCountry = useCallback(
    (country, path) => event => {
      if (event && event.target && event.target.classList.contains('infoIcon')) {
        return;
      }
      if (!showBars) {
        return;
      }
      if (!country) {
        setHoveredCountry(c => ({ ...c, opacity: 0 }));
        return;
      }
      // console.log(country)
      const mapPosition = mapRef.current.getBoundingClientRect();
      const x = event.clientX - mapPosition.left;
      const y = event.clientY - mapPosition.top;
      // console.log(country, x,y)
      // console.log('setHoveredCountry', country)
      setHoveredCountry({
        country,
        x,
        y,
        path,
        opacity: 1,
      });
    },
    [showBars]
  );

  const amount = chinaAfricaLoansData ? sum(chinaAfricaLoansData, d => d.amount) : 0;
  const count = chinaAfricaLoansData ? chinaAfricaLoansData.length : 0;

  //Tooltip rollover logic
  let tooltip = null;
  if (hoveredCountry) {
    const flipX = hoveredCountry.x > width / 2;
    const flipY = hoveredCountry.y > height * 0.66;
    const x = hoveredCountry.x + (flipX ? -5 : 5);
    const y = hoveredCountry.y + (inIntro ? window.scrollY : 0);
    const tooltipStyle = {
      transform: `translate(${x}px, ${y}px) ${flipX ? `translateX(-100%)` : ''} ${
        flipY ? `translateY(-100%)` : ''
      }`,
      opacity: hoveredCountry.opacity,
    };
    if (hoveredCountry.country) {
      const countryName = hoveredCountry.country.properties.name;
      const countryData = loansGroupedByCountryMap[countryName];
      const hasData = allData.some(d => d.Country === countryName);
      // console.log(hoveredCountry, countryData)
      if (hoveredCountry.path && !countryData && hasData) {
        // don't display a tooltip
      } else if (hoveredCountry.path && !countryData) {
        tooltip = (
          <div
            className="tooltip"
            style={{ ...tooltipStyle, padding: '0.5em', fontWeight: '600', fontSize: '0.875em' }}
          >
            {Localization.getWithArguments('noProjectsInCountry', Localization.get(countryName, { optional: true }))}
            {console.log('noProjectsInCountry', Localization.get(countryName))}
          </div>
        );
      } else if (countryData && !inIntro && !hoveredCountry.path) {
        const byType = Array.from(group(countryData.loans, typeAccessor))
          .map(d => ({
            type: d[0],
            loans: d[1],
            total: sum(d[1], d => d.amount),
          }))
          .sort((a, b) => b.total - a.total);
        const rowHeight = 20;
        const height = rowHeight * byType.length;
        const maxTextWidth = measureMaxTextWidth(
          byType.map(({ type }) => shortenCategoryText(type)),
          '0.875em',
          '600'
        );
        const marginsLeft = maxTextWidth + 16;
        const marginsRight = 120;
        const svgWidth = 200 + marginsLeft + marginsRight;
        const width = svgWidth - marginsLeft - marginsRight;
        const xExtent = extent(byType, d => d.total);
        // console.log(xExtent)
        const xScale = scaleLinear().domain([0, xExtent[1]]).range([0, width]);

        // console.log(byType)
        tooltip = (
          <div className="tooltip" style={tooltipStyle}>
            <div className="tooltipHeader">
              <div className="instruction">
                {Localization.getWithArguments(
                  'clickToSelectAndUpdateMapDisplay',
                  Localization.get(countryName, { optional: true })
                )}
              </div>
              <div className="tooltip__title">
                {Localization.get(countryName, { optional: true })}
              </div>
              <div style={{ fontSize: '0.875em', fontWeight: '600' }}>
                {Localization.get('amount')}: {moneyFormatter(countryData.total)}
                <span style={{ display: 'inline-block', marginLeft: '1em' }}>
                  {Localization.get('noOfLoans')}: {countryData.loans.length}
                </span>
              </div>
            </div>
            <div className="tooltipContent">
              <svg width={svgWidth} height={height} fontSize={'0.875em'} fontWeight="600">
                <g transform={`translate(${marginsLeft}, 0)`}>
                  {byType.map(({ type, loans, total }, yIndex) => {
                    const y = yIndex * rowHeight;
                    const width = xScale(total);
                    const fill = colors[type];
                    const textDy = '0.9em';
                    return (
                      <g transform={`translate(0, ${y})`} key={type}>
                        <text x={-5} dy={textDy} textAnchor="end">
                          {shortenCategoryText(Localization.get(type, { optional: true }))}
                        </text>
                        <rect fill={fill} width={width} height={rowHeight * 0.8} />
                        <text x={width} dy={textDy} dx={5}>
                          {moneyFormatter(total)} ({loans.length} {loans.length > 1 ? Localization.get('loans') : Localization.get('loan')})
                        </text>
                      </g>
                    );
                  })}
                </g>
              </svg>
            </div>
          </div>
        );
      } else if (hoveredCountry && inIntro && !hoveredCountry.path) {
        const percents = [
          {
            label: Localization.get('amountOfAfrica'),
            percent: countryData.total / amount,
          },
          {
            label: Localization.get('noloa'),
            percent: countryData.loans.length / count,
          },
        ];
        tooltip = (
          <div className="tooltip" style={tooltipStyle}>
            <div className="tooltipHeader">
              <div className="instruction">
                {Localization.getWithArguments('clickExploreText', Localization.get(countryName, { optional: true }))}
              </div>
              <div className="tooltip__title">{Localization.get(countryName, { optional: true })}</div>
              <div style={{ fontSize: '0.875em', fontWeight: '600' }}>
                {Localization.get('amount')}: {moneyFormatter(countryData.total)}
                <span style={{ display: 'inline-block', marginLeft: '1em' }}>
                  {Localization.get('noOfLoans')}: {countryData.loans.length}
                </span>
              </div>
            </div>
            <div className="tooltipContent">
              {percents.map(({ label, percent }) => {
                const width = 300;
                const height = 40;
                const fillWidth = width * percent;
                return (
                  <div key={label} fontSize="0.875em">
                    <div className="label" style={{ fontWeight: '600' }}>
                      {label}
                    </div>
                    <svg width={width} height={height}>
                      <rect
                        fill="#C80713"
                        strokeWidth="2"
                        stroke={'#cc0000'}
                        fillOpacity="0.3"
                        width={width}
                        height={height}
                      />
                      <rect fill="#cc0000" width={fillWidth} height={height} />
                      <text x="5" dy="1em" fill="#fff" fontWeight="bold">
                        {Localization.get(countryName, { optional: true })}
                      </text>
                      <text x="5" dy="2.2em" fill="#fff" fontWeight="bold">
                        {(percent * 100).toFixed(1)}%
                      </text>
                    </svg>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
    } else {
      tooltip = <div className="tooltip" style={tooltipStyle} />;
    }
  }

  let regionalIcon = (() => {
    if (!inIntro || showBars) {
      const regionalIconWidth = 209 / 2;
      const regionalIconHeight = 157 / 2;
      return (
        <g transform={`translate(-4, 8)`}>
          <image
            xlinkHref={regionalAfricaIcon}
            width={regionalIconWidth}
            height={regionalIconHeight}
            x={-regionalIconWidth / 2}
            y={-regionalIconHeight / 2}
          />
          <text y={regionalIconHeight / 2} dy="1em" fontWeight="600" textAnchor="middle">
            {Localization.get('regionalLoans')}
          </text>
          <image
            xlinkHref={infoIcon}
            width={14}
            height={14}
            y={regionalIconHeight / 2 + 4}
            x={58}
            style={{ pointerEvents: 'all' }}
            className="infoIcon"
            data-tip={Localization.get('regionalLoansInfo')}
          />
        </g>
      );
    }
    return null;
  })();

  const showLegend = amountExtent[0] != null && showBars;
  const countryColorScaleLegend =
    showLegend && amountExtent[1] ? (
      <div className="countryColorScale" style={{ bottom: inIntro ? null : '20px' }}>
        <div>{Localization.get('committedAmountPerCountry')}</div>
        <svg width={300} height={40}>
          <defs>
            <linearGradient id="countryColorScaleGradient">
              <stop offset="0%" stopColor="#f7f7f7" />
              <stop offset="100%" stopColor={maxColor} />
            </linearGradient>
          </defs>
          <rect y={5} width={300} height={15} fill="url(#countryColorScaleGradient)" />
          <text dy="1em" y={30} fill="#000" textAnchor="middle" x={0}>
            {moneyFormatter(amountExtent[0])}
          </text>
          <text dy="1em" y={30} fill="#000" textAnchor="middle" x={300}>
            {moneyFormatter(amountExtent[1])}
          </text>
          <line y1={5} y2={25} stroke="#000" />
          <line y1={5} y2={25} stroke="#000" x1={300} x2={300} />
        </svg>
      </div>
    ) : null;

  let graphTitles = null;
  if (showLegend) {
    const yearExtent = extent(chinaAfricaLoansData, d => d.Year);
    const yearLabel = inIntro ? `${yearExtent[0]} - ${yearExtent[1]}` : null;
    const countryLabel =
      selectedCountries === null
        ? 'Africa'
        : selectedCountries.length < 3
        ? selectedCountries.map(country => Localization.get(country, { optional: true })).join(', ')
        : Localization.get('multipleCountries');
    graphTitles = (
      <div className="graphTitles">
        <div className="year">{yearLabel}</div>
        <div className="countryLabel">{Localization.get(countryLabel)}</div>
        <div className="amount">
          {Localization.get('amount')} {moneyFormatter(amount)}
        </div>
        <div className="amount">
          {Localization.get('noOfLoans')} {(+count).toLocaleString()}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames('sizeContainer', { inIntro })}
        style={{
          width,
          height,
          pointerEvents: transition ? 'none' : 'auto',
          top: inIntro ? paddingTop : 0,
        }}
      >
        {colorScaleLegend}
        {countryColorScaleLegend}
        {graphTitles}
        <MapInteraction
          showControls={false}
          disableZoom={true}
          controlsClass="controls"
          minScale={settings.scale.min}
          maxScale={settings.scale.max}
          scale={transform.scale}
          translation={transform.translation}
          onChange={updateTransform}
        >
          {({ translation, scale }) => {
            const { x, y } = translation;
            return (
              <div style={{ postion: 'relative' }} ref={mapRef}>
                <div
                  className={`mapContainer ${transition ? 'transition' : ''}`}
                  onDoubleClick={e => {
                    const { target } = e;
                    const { id } = target;
                    if (id === 'Map') {
                      resetMapTransition();
                    }
                  }}
                  ref={mapRef}
                >
                  <svg key="Map" id="Map" className="Map" width={props.width} height={props.height}>
                    <g
                      key="animated"
                      className="transformContainer"
                      style={{ transform: `translate(${x}px, ${y}px) scale(${scale})` }}
                    >
                      <MapRenderer
                        width={width}
                        barScaledHeight={barScaledHeight}
                        height={height}
                        scale={scale}
                        paths={paths}
                        valueKey={props.valueKey}
                        // geoSelection={geoSelection}
                        bars={countryBars}
                        years={props.years}
                        geoType={props.geoType}
                        // updateGeoSelection={updateGeoSelection}
                        tooltipData={props.tooltipData}
                        setTooltipData={props.setTooltipData}
                        extraGradients={props.extraGradients}
                        lockedProject={props.lockedProject}
                        africaOutlineColor={africaOutlineColor}
                        africaOutlineStrokeWidth={africaOutlineStrokeWidth}
                        updateLockedProject={props.updateLockedProject}
                        showWorldOutline={showWorldOutline}
                        selectCountry={clickCountry}
                        hoverCountry={hoverCountry}
                        inIntro={inIntro}
                        regionalIcon={regionalIcon}
                        setInIntro={setInIntro}
                      />
                    </g>
                    <MapRenderer
                      width={width}
                      height={height}
                      scale={scale}
                      valueKey={props.valueKey}
                      // geoSelection={geoSelection}
                      bars={regionalBars}
                      years={props.years}
                      geoType={props.geoType}
                      // updateGeoSelection={updateGeoSelection}
                      tooltipData={props.tooltipData}
                      setTooltipData={props.setTooltipData}
                      extraGradients={props.extraGradients}
                      lockedProject={props.lockedProject}
                      updateLockedProject={props.updateLockedProject}
                      selectCountry={clickCountry}
                      hoverCountry={hoverCountry}
                      inIntro={inIntro}
                      regionalIcon={regionalIcon}
                      setInIntro={setInIntro}
                      barScaledHeight={barScaledHeight}
                    />
                  </svg>
                </div>
                {inIntro ? null : tooltip}
              </div>
            );
          }}
        </MapInteraction>
      </div>

      {inIntro ? tooltip : null}
    </>
  );
}

function MapRenderer(props) {
  const {
    paths,
    lockedProject,
    africaOutlineColor,
    africaOutlineStrokeWidth,
    showWorldOutline,
    selectCountry,
    hoverCountry,
    inIntro,
    regionalIcon,
    setInIntro,
    width,
    barScaledHeight,
  } = props;

  const stacks = useMemo(
    () =>
      sortBy(
        props.bars.map(s => {
          s.screen = coordinateToScreen(s.transform, props.width, props.height);
          return s;
        }),
        s => s.screen[1]
      ),
    [props.bars, props.width, props.height]
  );
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const clickCountry = useCallback(
    country => () => {
      const exists = selectCountry(country)();
      if (!exists) {
        return;
      }
      if (inIntro) {
        window.scrollTo(0, 0);
        setInIntro(false);
      }
    },
    [selectCountry, inIntro, setInIntro]
  );
  const clickStack = useCallback(
    stack => () => {
      // console.log(stack)
      selectCountry(stack.feature)();
      if (inIntro) {
        window.scrollTo(0, 0);
        setInIntro(false);
      }
    },
    [selectCountry, inIntro, setInIntro]
  );

  // console.log(paths)
  const countryPaths = paths ? paths.countries : null;
  const countries = useMemo(() => {
    if (!countryPaths) {
      return null;
    }
    return countryPaths.map(c => {
      let mouseMove = noop;
      let mouseOut = noop;
      let cursor = inIntro ? 'default' : 'pointer';
      if (!c.hasData) {
        cursor = 'default';
        mouseMove = hoverCountry(c, true);
        mouseOut = hoverCountry(null);
      }
      if (c.fill === 'transparent') {
        cursor = 'default';
        mouseMove = noop;
        mouseOut = noop;
      }
      let clickable = !inIntro && c.fill === '#fff' && c.hasData;
      return (
        <path
          className={classNames('country', { clickable })}
          key={c.id}
          d={c.d}
          stroke={c.stroke}
          fill={c.fill}
          style={{ cursor }}
          onMouseMove={mouseMove}
          onMouseOut={mouseOut}
          onMouseEnter={mouseMove}
          onClick={clickCountry(c)}
          onTouchStart={clickCountry(c)}
        />
      );
    });
  }, [countryPaths, clickCountry, hoverCountry, inIntro]);

  const shadows = useMemo(
    () =>
      stacks.map(s => {
        if (s.name === 'Regional') {
          return null;
        }
        return (
          <path
            key={`${s.name}-shadow`}
            className="shadow"
            transform={`translate(${s.transform}) scale(${
              1 / props.scale
            }) scale(1, ${barScaledHeight})`}
            d={s.shadowPath}
            fill="url(#shadow)"
          />
        );
      }),
    [stacks, props.scale, barScaledHeight]
  );

  const stacksX = (props.scale / settings.scale.max) * 0.095;
  const stacksY = (props.scale / settings.scale.max) * -0.68;
  const stackedBars = useMemo(
    () =>
      stacks.map(s => {
        let transform = `translate(${s.screen}) scale(${
          1 / props.scale
        }) scale(1 ${barScaledHeight})`;
        if (s.name === 'Regional') {
          transform = `translate(${width - 100}, 100) scale(1 ${barScaledHeight})`;
        }
        let stack = null;
        if (s.feature.properties.name === 'Regional') {
          stack = (
            <g key={s.name}>
              {s.feature.properties.name === 'Regional' ? (
                <g transform={transform} onClick={clickCountry(s.feature)}>
                  {' '}
                  {regionalIcon}
                </g>
              ) : null}

              <g
                className="stack"
                transform={transform}
                onMouseMove={hoverCountry(s.feature)}
                onMouseEnter={hoverCountry(s.feature)}
                onMouseOut={hoverCountry(null)}
                onClick={clickStack(s)}
                onTouchStart={clickStack(s)}
              >
                {s.bars.map((g, i) => {
                  const locked =
                    lockedProject && g.projects
                      ? lockedProject.plantID === g.projects[0].plantID
                      : false;
                  if (g.path.includes('NaN')) {
                    return null;
                  }
                  return (
                    <path
                      data-stack={g.key}
                      key={i}
                      d={g.path}
                      stroke="#000"
                      fill={g.fill}
                      opacity={locked || !lockedProject ? 0.9 : 0.3}
                      filter={locked ? 'url(#black-glow)' : ''}
                    />
                  );
                })}
              </g>
            </g>
          );
        } else {
          stack = (
            <g
              key={s.name}
              className="stack"
              transform={transform}
              onMouseMove={hoverCountry(s.feature)}
              onMouseEnter={hoverCountry(s.feature)}
              onMouseOut={hoverCountry(null)}
              onClick={clickStack(s)}
              onTouchStart={clickStack(s)}
            >
              {s.bars.map((g, i) => {
                const locked =
                  lockedProject && g.projects
                    ? lockedProject.plantID === g.projects[0].plantID
                    : false;
                if (g.path.includes('NaN')) {
                  return null;
                }
                return (
                  <path
                    data-stack={g.key}
                    key={i}
                    d={g.path}
                    stroke="#000"
                    fill={g.fill}
                    opacity={locked || !lockedProject ? 0.9 : 0.3}
                    filter={locked ? 'url(#black-glow)' : ''}
                  />
                );
              })}
            </g>
          );
        }

        return stack;
      }),
    [
      lockedProject,
      props.scale,
      stacks,
      clickStack,
      hoverCountry,
      regionalIcon,
      width,
      barScaledHeight,
      clickCountry,
    ]
  );
  return (
    <g id="MapRenderer" key="MapRenderer" className="MapRenderer">
      <MapGradients>
        {props.extraGradients}
        <linearGradient
          id={'fillGradient'}
          x1="0%"
          x2="50%"
          y1="0%"
          y2="50%"
          gradientTransform={'skewX(45)'}
        >
          <stop offset="0%" stopColor={'#CC0000'} />
          <stop offset="33%" stopColor={'#CC0000'} />
          <stop offset="34%" stopColor={'#FFA8AD'} />
          <stop offset="100%" stopColor={'#FFA8AD'} />
        </linearGradient>
      </MapGradients>
      <g
        key="countries"
        className="countries"
        transform={`
          translate(${props.width / 2}, ${props.height / 2})
          matrix(${rotationMatrix})
          translate(${-props.width / 2}, ${-props.height / 2})
        `}
        style={{ strokeWidth: 1 / props.scale }}
      >
        {showWorldOutline ? (
          <path
            key="background"
            className="background"
            transform={`translate(${-2 / props.scale}, ${2 / props.scale})`}
            d={paths.outline}
          />
        ) : null}
        {paths && paths.africa ? (
          <path
            transform={`translate(${-2 / props.scale}, ${2 / props.scale})`}
            d={paths.africa}
            fill={africaOutlineColor}
            stroke={africaOutlineColor}
            strokeWidth={africaOutlineStrokeWidth}
          />
        ) : null}
        <g>{countries}</g>
        {paths && paths.disputedLine ? (
          <g>
            <path
              transform={`translate(${-2 / props.scale}, ${2 / props.scale})`}
              d={paths.disputedLine}
              stroke="white"
              strokeWidth={(1 / props.scale) * 6}
              fill="none"
            />
            <path
              transform={`translate(${-2 / props.scale}, ${2 / props.scale})`}
              d={paths.disputedLine}
              stroke="#ccc"
              strokeWidth="1"
              strokeDasharray="1, 1"
              fill="none"
            />
          </g>
        ) : null}
        <g>{shadows}</g>
      </g>
      <g key="stacks" className="stacks" transform={`translate(${stacksX}, ${stacksY})`}>
        {stackedBars}
      </g>
    </g>
  );
}
