import React from 'react';
import { colors } from './settings.js';

const lightColors = [...new Set(Object.keys(colors).map(k => colors[k].light))];
const chordGradients = [];
lightColors.forEach(c0 => {
  lightColors.forEach(c1 => {
    if (c0 !== c1) {
      chordGradients.push([c0, c1]);
    }
  });
});

export function MapGradients(props) {
  return (
    <defs>
      {Object.keys(colors).map(c => {
        const colorID = c.replace(/ |\(|\)/g, '-');
        return (
          <linearGradient
            key={colorID}
            id={colorID}
            x1="0%"
            x2="50%"
            y1="0%"
            y2="50%"
            gradientTransform={'skewX(45)'}
          >
            <stop offset="0%" stopColor={colors[c].dark} />
            <stop offset="33%" stopColor={colors[c].dark} />
            <stop offset="34%" stopColor={colors[c].light} />
            <stop offset="100%" stopColor={colors[c].light} />
          </linearGradient>
        );
      })}
      <linearGradient key="shadow" id="shadow" x1="0%" x2="0%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="rgba(0, 0, 0, 0)" />
        <stop offset="100%" stopColor="rgba(0, 0, 0, 0.35)" />
      </linearGradient>
      <filter id="black-glow" x="-100%" y="-100%" width="300%" height="300%">
        <feFlood result="flood" floodColor="#000000" floodOpacity="1"></feFlood>
        <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
        <feMorphology in="mask" result="dilated" operator="dilate" radius="2"></feMorphology>
        <feGaussianBlur in="dilated" result="blurred" stdDeviation="2"></feGaussianBlur>
        <feMerge>
          <feMergeNode in="blurred"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      {props.children}
    </defs>
  );
}

export function ChordGradients(props) {
  return (
    <defs>
      {chordGradients.map(([c0, c1]) => {
        const key = `chordGradient-${c0.replace(/#/g, '')}-${c1.replace(/#/g, '')}`;
        if (props.gradientsList != null) {
          if (!props.gradientsList.includes(key)) {
            return null;
          }
        }
        return (
          <linearGradient key={key} id={key} x1="0" x2="1" y1="0" y2="0">
            <stop offset="0" stopColor={c0} />
            <stop offset="1" stopColor={c1} />
          </linearGradient>
        );
      })}
    </defs>
  );
}

ChordGradients.defaultProps = {
  gradientsList: null,
};
