import languages from './languages';
import { enObj } from './en.js';
import { zhObj } from './zh.js';
import React from 'react';

class Localization {
  constructor() {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    this.setLanguage(lang);
    this._localization = {};
    languages.forEach(lang => {
      this._localization[lang.locale] = lang.locale === 'zh' ? zhObj : enObj;
    });
  }

  setLanguage(lang) {
    this.language = this._checkLanguage(lang) ? lang : 'en';
    if (!this._defaultTitle) {
      this._defaultTitle = document.title;
    }
    if (this.language === 'zh') {
      window.document.title = '中国对非洲贷款数据库';
    } else {
      window.document.title = this._defaultTitle;
    }
  }

  getLanguages() {
    return languages;
  }

  getLanguage() {
    return this.language;
  }

  get(key, {optional = false, forceLanguage = null} = {}) {
    if (!key) {
      return;
    }
    const language = forceLanguage || this.language;
    
    if (!this._localization[language][key]) {
      if (optional) {
        return key;
      }
      // for testing
      const ignore = [];
      if (!ignore.includes(key)) {
        //console.log(`Localization for ${key} in ${language} not found`);
      }
      // for testing
      return key;
    }
    if (typeof this._localization[language][key] !== 'string' || React.isValidElement(this._localization[language][key])) {
      //console.log(`Localization for ${key} is not a string or React element`);
      return key
    }
    return this._localization[language][key];
  }

  getWithArguments(key, args) {
    if (!key) {
      return;
    }
    const language = this.language;
    if (!this._localization[language][key]) {
      // for testing
      const ignore = [];
      if (!ignore.includes(key)) {
        //console.log(`Localization for ${key} in ${language} not found`);
      }
      // for testing
      return key;
    }
    if (typeof this._localization[language][key] !== 'function') {
      console.log(`Localization for ${key} is not a function`);
      return key
    }
    return this._localization[language][key](args);
  }

  _checkLanguage(locale) {
    let match = false;
    languages.forEach(lang => {
      if (lang.locale === locale) {
        match = true;
      }
    });
    return match;
  }
}

export default new Localization();
