const countries = [
  {"name":"Afghanistan","caps":"AFGHANISTAN","code":"AFG","id":"004"},
  {"name":"Albania","caps":"ALBANIA","code":"ALB","id":"008"},
  {"name":"Algeria","caps":"ALGERIA","code":"DZA","id":"012"},
  {"name":"Angola","caps":"ANGOLA","code":"AGO","id":"024"},
  {"name":"Antigua and Barbuda","caps":"ANTIGUA AND BARBUDA","code":"ATG","id":"028"},
  {"name":"Argentina","caps":"ARGENTINA","code":"ARG","id":"032"},
  {"name":"Australia","caps":"AUSTRALIA","code":"AUS","id":"036"},
  {"name":"Austria","caps":"AUSTRIA","code":"AUT","id":"040"},
  {"name":"Bahamas","caps":"BAHAMAS","code":"BHS","id":"044"},
  {"name":"Bangladesh","caps":"BANGLADESH","code":"BGD","id":"050"},
  {"name":"Barbados","caps":"BARBADOS","code":"BRB","id":"052"},
  {"name":"Belarus","caps":"BELARUS","code":"BLR","id":"112"},
  {"name":"Belgium","caps":"BELGIUM","code":"BEL","id":"056"},
  {"name":"Belize","caps":"BELIZE","code":"BLZ","id":"084"},
  {"name":"Benin","caps":"BENIN","code":"BEN","id":"204"},
  {"name":"Bolivia","caps":"BOLIVIA","code":"BOL","id":"068"},
  {"name":"Bosnia and Herzegovina","caps":"BOSNIA-HERZEGOVINA","code":"BIH","id":"070"},
  {"name":"Botswana","caps":"BOTSWANA","code":"BWA","id":"072"},
  {"name":"Brazil","caps":"BRAZIL","code":"BRA","id":"076"},
  {"name":"Bulgaria","caps":"BULGARIA","code":"BGR","id":"100"},
  {"name":"Burkina Faso","caps":"BURKINA FASO","code":"BFA","id":"854"},
  {"name":"Cambodia","caps":"CAMBODIA","code":"KHM","id":"116"},
  {"name":"Cameroon","caps":"CAMEROON","code":"CMR","id":"120"},
  {"name":"Canada","caps":"CANADA","code":"CAN","id":"124"},
  {"name":"Chad","caps":"CHAD","code":"TCD","id":"148"},
  {"name":"Chile","caps":"CHILE","code":"CHL","id":"152"},
  {"name":"China","caps":"CHINA","code":"CHN","id":"156"},
  {"name":"Colombia","caps":"COLOMBIA","code":"COL","id":"170"},
  {"name":"Congo","caps":"CONGO","code":"COG","id":"178"},
  {"name":"Congo, Dem. Rep.","caps":"CONGO DEM REP","code":"COD","id":"180"},
  {"name":"Costa Rica","caps":"COSTA RICA","code":"CRI","id":"188"},
  {"name":"Côte d’Ivoire","caps":"COTE D'IVOIRE","code":"CIV","id":"384"},
  {"name":"Croatia","caps":"CROATIA","code":"HRV","id":"191"},
  {"name":"Cuba","caps":"CUBA","code":"CUB","id":"192"},
  {"name":"Czech Republic","caps":"CZECH REPUBLIC","code":"CZE","id":"203"},
  {"name":"Denmark","caps":"DENMARK","code":"DNK","id":"208"},
  {"name":"Dominica","caps":"DOMINICA","code":"DMA","id":"212"},
  {"name":"Dominican Republic","caps":"DOMINICAN REPUBLIC","code":"DOM","id":"214"},
  {"name":"Ecuador","caps":"ECUADOR","code":"ECU","id":"218"},
  {"name":"Egypt","caps":"EGYPT","code":"EGY","id":"818"},
  {"name":"El Salvador","caps":"EL SALVADOR","code":"SLV","id":"222"},
  {"name":"Equatorial Guinea","caps":"EQUATORIAL GUINEA","code":"GNQ","id":"226"},
  {"name":"Estonia","caps":"ESTONIA","code":"EST","id":"233"},
  {"name":"Ethiopia","caps":"ETHIOPIA","code":"ETH","id":"231"},
  {"name":"Fiji","caps":"FIJI","code":"FJI","id":"242"},
  {"name":"Finland","caps":"FINLAND","code":"FIN","id":"246"},
  {"name":"France","caps":"FRANCE","code":"FRA","id":"250"},
  {"name":"Gabon","caps":"GABON","code":"GAB","id":"266"},
  {"name":"Gambia","caps":"GAMBIA","code":"GMB","id":"270"},
  {"name":"Georgia","caps":"GEORGIA","code":"GEO","id":"268"},
  {"name":"Germany","caps":"GERMANY","code":"DEU","id":"276"},
  {"name":"Ghana","caps":"GHANA","code":"GHA","id":"288"},
  {"name":"Greece","caps":"GREECE","code":"GRC","id":"300"},
  {"name":"Grenada","caps":"GRENADA","code":"GRD","id":"308"},
  {"name":"Guatemala","caps":"GUATEMALA","code":"GTM","id":"320"},
  {"name":"Guinea","caps":"GUINEA","code":"GIN","id":"324"},
  {"name":"Guyana","caps":"GUYANA","code":"GUY","id":"328"},
  {"name":"Honduras","caps":"HONDURAS","code":"HND","id":"340"},
  {"name":"Hong Kong SAR, China","caps":"HONG KONG SAR, CHINA","code":"HKG","id":"344"},
  {"name":"Hungary","caps":"HUNGARY","code":"HUN","id":"348"},
  {"name":"India","caps":"INDIA","code":"IND","id":"356"},
  {"name":"Indonesia","caps":"INDONESIA","code":"IDN","id":"360"},
  {"name":"Iran","caps":"IRAN","code":"IRN","id":"364"},
  {"name":"Ireland","caps":"IRELAND","code":"IRL","id":"372"},
  {"name":"Italy","caps":"ITALY","code":"ITA","id":"380"},
  {"name":"Jamaica","caps":"JAMAICA","code":"JAM","id":"388"},
  {"name":"Japan","caps":"JAPAN","code":"JPN","id":"392"},
  {"name":"Jordan","caps":"JORDAN","code":"JOR","id":"400"},
  {"name":"Kazakhstan","caps":"KAZAKHSTAN","code":"KAZ","id":"398"},
  {"name":"Kenya","caps":"KENYA","code":"KEN","id":"404"},
  {"name":"Kyrgyzstan","caps":"KYRGYZSTAN","code":"KGZ","id":"417"},
  {"name":"Laos","caps":"LAOS","code":"LAO","id":"418"},
  {"name":"Lebanon","caps":"LEBANON","code":"LBN","id":"422"},
  {"name":"Liberia","caps":"LIBERIA","code":"LBR","id":"430"},
  {"name":"Macedonia, FYR","caps":"MACEDONIA, FYR","code":"MKD","id":"807"},
  {"name":"Madagascar","caps":"MADAGASCAR","code":"MDG","id":"450"},
  {"name":"Malawi","caps":"MALAWI","code":"MWI","id":"454"},
  {"name":"Malaysia","caps":"MALAYSIA","code":"MYS","id":"458"},
  {"name":"Maldives","caps":"MALDIVES","code":"MDV","id":"462"},
  {"name":"Mali","caps":"MALI","code":"MLI","id":"466"},
  {"name":"Malta","caps":"MALTA","code":"MLT","id":"470"},
  {"name":"Mauritania","caps":"MAURITANIA","code":"MRT","id":"478"},
  {"name":"Mauritius","caps":"MAURITIUS","code":"MUS","id":"480"},
  {"name":"Mexico","caps":"MEXICO","code":"MEX","id":"484"},
  {"name":"Moldova","caps":"MOLDOVA","code":"MDA","id":"498"},
  {"name":"Mongolia","caps":"MONGOLIA","code":"MNG","id":"496"},
  {"name":"Montenegro","caps":"MONTENEGRO","code":"MNE","id":"499"},
  {"name":"Morocco","caps":"MOROCCO","code":"MAR","id":"504"},
  {"name":"Mozambique","caps":"MOZAMBIQUE","code":"MOZ","id":"508"},
  {"name":"Myanmar","caps":"MYANMAR","code":"MMR","id":"104"},
  {"name":"Namibia","caps":"NAMIBIA","code":"NAM","id":"516"},
  {"name":"Nepal","caps":"NEPAL","code":"NPL","id":"524"},
  {"name":"Netherlands","caps":"NETHERLANDS","code":"NLD","id":"528"},
  {"name":"New Zealand","caps":"NEW ZEALAND","code":"NZL","id":"554"},
  {"name":"Nicaragua","caps":"NICARAGUA","code":"NIC","id":"558"},
  {"name":"Niger","caps":"NIGER","code":"NER","id":"562"},
  {"name":"Nigeria","caps":"NIGERIA","code":"NGA","id":"566"},
  {"name":"Norway","caps":"NORWAY","code":"NOR","id":"578"},
  {"name":"Pakistan","caps":"PAKISTAN","code":"PAK","id":"586"},
  {"name":"Panama","caps":"PANAMA","code":"PAN","id":"591"},
  {"name":"Papua New Guinea","caps":"PAPUA NEW GUINEA","code":"PNG","id":"598"},
  {"name":"Peru","caps":"PERU","code":"PER","id":"604"},
  {"name":"Philippines","caps":"PHILIPPINES","code":"PHL","id":"608"},
  {"name":"Poland","caps":"POLAND","code":"POL","id":"616"},
  {"name":"Portugal","caps":"PORTUGAL","code":"PRT","id":"620"},
  {"name":"Republic of Korea","caps":"SOUTH KOREA","code":"KOR","id":"410"},
  {"name":"Romania","caps":"ROMANIA","code":"ROU","id":"642"},
  {"name":"Russia","caps":"RUSSIA","code":"RUS","id":"643"},
  {"name":"Rwanda","caps":"RWANDA","code":"RWA","id":"646"},
  {"name":"Senegal","caps":"SENEGAL","code":"SEN","id":"686"},
  {"name":"Serbia","caps":"SERBIA","code":"SRB","id":"688"},
  {"name":"Sierra Leone","caps":"SIERRA LEONE","code":"SLE","id":"694"},
  {"name":"Singapore","caps":"SINGAPORE","code":"SGP","id":"702"},
  {"name":"Slovenia","caps":"SLOVENIA","code":"SVN","id":"705"},
  {"name":"South Africa","caps":"SOUTH AFRICA","code":"ZAF","id":"710"},
  {"name":"South Sudan","caps":"SOUTH SUDAN","code":"SSD","id":"728"},
  {"name":"Spain","caps":"SPAIN","code":"ESP","id":"724"},
  {"name":"Sri Lanka","caps":"SRI LANKA","code":"LKA","id":"144"},
  {"name":"St. Kitts and Nevis","caps":"ST. KITTS AND NEVIS","code":"KNA","id":"659"},
  {"name":"St. Lucia","caps":"ST. LUCIA","code":"LCA","id":"662"},
  {"name":"St. Vincent and the Grenadines","caps":"ST. VINCENT AND THE GRENADINES","code":"VCT","id":"670"},
  {"name":"Sudan","caps":"SUDAN","code":"SDN","id":"729"},
  {"name":"Suriname","caps":"SURINAME","code":"SUR","id":"740"},
  {"name":"Sweden","caps":"SWEDEN","code":"SWE","id":"752"},
  {"name":"Tajikistan","caps":"TAJIKISTAN","code":"TJK","id":"762"},
  {"name":"Tanzania","caps":"TANZANIA","code":"TZA","id":"834"},
  {"name":"Thailand","caps":"THAILAND","code":"THA","id":"764"},
  {"name":"Togo","caps":"TOGO","code":"TGO","id":"768"},
  {"name":"Trinidad and Tobago","caps":"TRINIDAD AND TOBAGO","code":"TTO","id":"780"},
  {"name":"Tunisia","caps":"TUNISIA","code":"TUN","id":"788"},
  {"name":"Turkey","caps":"TURKEY","code":"TUR","id":"792"},
  {"name":"Uganda","caps":"UGANDA","code":"UGA","id":"800"},
  {"name":"Ukraine","caps":"UKRAINE","code":"UKR","id":"804"},
  {"name":"United Arab Emirates","caps":"UNITED ARAB EMIRATES","code":"ARE","id":"784"},
  {"name":"United Kingdom","caps":"UK","code":"GBR","id":"826"},
  {"name":"United States","caps":"USA","code":"USA","id":"840"},
  {"name":"Uruguay","caps":"URUGUAY","code":"URY","id":"858"},
  {"name":"Uzbekistan","caps":"UZBEKISTAN","code":"UZB","id":"860"},
  {"name":"Venezuela","caps":"VENEZUELA","code":"VEN","id":"862"},
  {"name":"Vietnam","caps":"VIETNAM","code":"VNM","id":"704"},
  {"name":"Zambia","caps":"ZAMBIA","code":"ZMB","id":"894"},
  {"name":"Zimbabwe","caps":"ZIMBABWE","code":"ZWE","id":"716"},
];

export function getCountryByCaps(caps) {
	return countries.find(c => c.caps === caps);
};

export function getCountryByName(name) {
	return countries.find(c => c.name === name);
};

export function getCountryByCode(code) {
	return countries.find(c => c.code === code);
};

export function getCountryById(id) {
	return countries.find(n => n.id === id);
};
