import './TypeFilterGraph.scss';
import { group, sum } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { moneyFormatter } from '.';
import colors from '../colors';
import { animated, useSpring } from 'react-spring';
import { useState, useRef } from 'react';
import YearFilterGraph from './YearFilterGraph';
import infoIcon from '../images/info_icon.svg';
import { shortenCategoryText } from '../utils';
import Localization from '../Localization';

const Box = props => {
  const {
    type,
    width,
    graphHeight,
    fill,
    rectOpacity,
    valueLabel,
    x,
    clickType,
    onMouseMove,
    onMouseLeave,
  } = props;
  const textX = 5;

  const clipPathId = `clipPath-${Math.round(Math.random() * 1000000)}`;
  const spring = useSpring({
    x,
    width,
  });
  return (
    <animated.g
      key={type.type}
      transform={spring.x.to(x => `translate(${x}, 0)`)}
      onClick={clickType(type.type)}
      fontWeight="600"
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseMove}
      style={{ cursor: 'pointer' }}
    >
      <clipPath id={clipPathId}>
        <animated.rect width={spring.width} height={graphHeight} />
      </clipPath>
      <animated.rect
        width={spring.width.to(v => Math.max(v - 5, 2))}
        height={graphHeight}
        fill={fill}
        opacity={rectOpacity}
      />
      <g clipPath={`url(#${clipPathId})`}>
        <text x={textX} dy={'1.2em'} fill="#fff">
          {shortenCategoryText(Localization.get(type.type, { optional: true }))}
        </text>
        <text x={textX} dy={'2.4em'} fill="#fff">
          {valueLabel}
        </text>
      </g>
    </animated.g>
  );
};
export default function TypeFilterGraph(props) {
  const {
    selectedTypes,
    setSelectedTypes,
    chinaAfricaLoansData,
    unit,
    width,
    typeAccessor,
    hideInstructions,
    yearExtent,
    type,
    inTooltip,
  } = props;

  const groupedByType = Array.from(group(chinaAfricaLoansData, typeAccessor))
    .map(group => {
      const loans = group[1];
      return {
        type: group[0],
        loans,
        count: loans.length,
        amount: sum(loans, d => d.amount),
      };
    })
    .sort((a, b) => (unit === 'amount' ? b.amount - a.amount : b.count - a.count));

  const margins = {
    left: 0,
    bottom: 20,
    right: 0,
    top: 2,
  };

  let height = 74;
  if (inTooltip) {
    height -= margins.bottom;
    margins.bottom = 0;
  }
  const xMax = sum(groupedByType, d => (unit === 'amount' ? d.amount : d.count));

  const xScale = scaleLinear()
    .domain([0, xMax])
    .range([0, width - margins.left - margins.right]);

  const graphHeight = height - margins.top - margins.bottom;

  const clickType = type => () => {
    let newTypes = null;
    if (selectedTypes === null) {
      newTypes = [type];
    } else if (selectedTypes.includes(type)) {
      newTypes = selectedTypes.filter(d => d !== type);
      if (newTypes.length === 0) {
        newTypes = null;
      }
    } else {
      newTypes = [...selectedTypes, type];
      newTypes.sort();
    }
    setSelectedTypes(newTypes);
  };
  let runningX = 0;
  // console.log(selectedTypes)
  const [hoveredType, setHoveredType] = useState(null);
  const svgRef = useRef();
  const hoverType = type => event => {
    if (!type) {
      setHoveredType(null);
      return;
    }
    const svgPosition = svgRef.current.getBoundingClientRect();
    const x = event.clientX - svgPosition.left;
    const y = event.clientY - svgPosition.top;
    setHoveredType({
      type,
      x,
      y,
      clientY: event.clientY,
    });
  };
  let tooltip = null;
  if (hoveredType) {
    const flipY = hoveredType.clientY > window.innerHeight * 0.75;
    const tooltipStyle = {
      transform: `translate(${hoveredType.x + 5}px, ${hoveredType.y}px) ${
        flipY ? 'translateY(-100%)' : ''
      }`,
    };
    const typeData = groupedByType.find(d => d.type === hoveredType.type);

    //selectedYears, setSelectedYears, chinaAfricaLoansData, yearExtent, unit, width,
    // typeAccessor, hideInstructions
    if (typeData) {
      tooltip = (
        <div className="typeFilterGraphTooltip tooltip" style={tooltipStyle}>
          <div className="tooltipHeader">
            <div className="instruction">
              {Localization.getWithArguments(
                'clickToSelectAndUpdateMapDisplay',
                Localization.get(hoveredType.type, { optional: true })
              )}
            </div>
            <div className="tooltip__title">
              {Localization.get(hoveredType.type, { optional: true })}
            </div>
            <div style={{ fontSize: '0.875em', fontWeight: '600' }}>
              {Localization.get('amount')}: {moneyFormatter(typeData.amount)}
              <span style={{ display: 'inline-block', marginLeft: '1em' }}>
                {Localization.get('noOfLoans')}: {typeData.count} {Localization.get('leanAmount')}
              </span>
            </div>
          </div>
          <div className="tooltipContent">
            <YearFilterGraph
              selectedYears={null}
              chinaAfricaLoansData={typeData.loans}
              yearExtent={yearExtent}
              unit={unit}
              width={440}
              typeAccessor={typeAccessor}
              hideInstructions={true}
            />
          </div>
        </div>
      );
    }
  }
  const typeRows = groupedByType.map((type, typeIndex) => {
    const value = unit === 'amount' ? type.amount : type.count;
    const x = runningX;
    const width = xScale(value);
    runningX += width;
    const fill = colors[type.type] || '#ebebe6';
    const valueLabel = unit === 'amount' ? moneyFormatter(value) : value;

    let rectOpacity = selectedTypes === null ? 1 : selectedTypes.includes(type.type) ? 1 : 0.2;
    if (hoveredType && hoveredType.type !== type.type) {
      rectOpacity = rectOpacity === 0.2 ? 0.1 : selectedTypes !== null ? 0.5 : 0.2;
    } else if (hoveredType && hoveredType.type === type.type) {
      rectOpacity = 1;
    }
    return (
      <Box
        x={x}
        type={type}
        key={type.type}
        width={width}
        graphHeight={graphHeight}
        fill={fill}
        rectOpacity={rectOpacity}
        valueLabel={valueLabel}
        clickType={clickType}
        onMouseMove={hoverType(type.type)}
        onMouseLeave={hoverType(null)}
      />
    );
  });

  // capitalize types
  const typesCapitalized = Localization.get(type);
  let lenderExplainer = null;
  let lenderExplainerIcon = null;
  const [showLenderExplainer, setShowLenderExplainer] = useState(false);
  if (type === 'lenders') {
    lenderExplainerIcon = (
      <img
        src={infoIcon}
        style={{ width: '0.7em' }}
        onMouseOver={e => setShowLenderExplainer(true)}
        onMouseOut={e => setShowLenderExplainer(false)}
        alt="mouse over for lender type explaination"
      />
    );
  }
  if (showLenderExplainer) {
    const types = [
      {
        name: Localization.get('policyBanks'),
        values: [
          { name: Localization.get('chexim'), acronym: Localization.get('CHEXIM') },
          { name: Localization.get('cdb'), acronym: Localization.get('CDB') },
        ],
      },
      {
        name: Localization.get('otherCNGov'),
        values: [
          { name: Localization.get('cidca'), acronym: Localization.get('CIDCA') },
          { name: Localization.get('pbc'), acronym: Localization.get('PBC') },
          { name: Localization.get('ucge'), acronym: Localization.get('CN Gov') },
        ],
      },
      {
        name: Localization.get('commericalBanks'),
        values: [
          { name: Localization.get('icbc'), acronym: Localization.get('ICBC') },
          { name: Localization.get('ccb'), acronym: Localization.get('CCB') },
          { name: Localization.get('abc'), acronym: Localization.get('ABC') },
          { name: Localization.get('citic'), acronym: Localization.get('CITIC') },
          { name: Localization.get('boc'), acronym: Localization.get('BoC') },
        ],
      },
      {
        name: Localization.get('contractors'),
        values: [{ name: Localization.get('pcosc'), acronym: Localization.get('companyName') }],
      },
      {
        name: Localization.get('otherCN'),
        values: [
          {
            name: Localization.get('mixedCN'),
            acronym: Localization.get('MixedCN:'),
          },
          {
            name: Localization.get('ambiguousLenders'),
            acronym: Localization.get('Other CN financing:'),
          },
        ],
      },
    ];
    
    const allValues = types.map(d => d.values).flat();
    lenderExplainer = (
      <div className="lenderExplainer">
        <table>
          <thead>
            <tr>
              <td>{Localization.get('type')}</td>
              <td>{Localization.get('lender')}</td>
              <td>{Localization.get('acronym')}</td>
            </tr>
          </thead>
          <tbody>
            {allValues.map((d, index) => {
              const matchingFirstColumn = types.find(t => t.values[0] === d);
              // const matchingLastColumn = types.find(t => t.values[t.values.length - 1] === d)
              const backgroundColor = index % 2 === 0 ? null : 'rgba(112, 112, 112, 0.15)';
              const style = {
                backgroundColor,
                border: '1px solid #707070',
                // borderLeft: '1px solid #707070',
                // borderRight: '1px solid #707070',
                // borderTop: matchingFirstColumn || matchingLastColumn ? '1px solid #707070' : null,
                // borderBottom: matchingFirstColumn || matchingLastColumn ? '1px solid #707070' : null,
              };
              const firstRowStyle = {
                backgroundColor: '#EBEBE6',
                verticalAlign: 'top',
                border: '1px solid #707070',
              };
              if (matchingFirstColumn === types[types.length - 1]) {
                // console.log('match')
                firstRowStyle.borderBottomLeftRadius = '0.5em';
              }
              return (
                <tr key={index}>
                  {matchingFirstColumn ? (
                    <td style={firstRowStyle} rowSpan={matchingFirstColumn.values.length}>
                      {matchingFirstColumn.name}
                    </td>
                  ) : null}
                  <td style={style}>{d.name}</td>
                  <td style={style}>{d.acronym}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="TypeFilterGraph">
      <div
        style={{
          lineHeight: '1.5em',
          marginBottom: '0.5em',
          display: hideInstructions ? 'none' : null,
        }}
      >
        <span style={{ fontWeight: 'bold' }}>
          {typesCapitalized} {lenderExplainerIcon}
        </span>{' '}
        <span className="instruction">{type === "sectors" ? Localization.get('selectOneOrMoreSectorsToUpdate') : Localization.get('selectOneOrMoreYearsToUpdate')}</span>
        <br />
        <div style={{ fontSize: '0.875em', fontWeight: '600' }}>
          {Localization.get('amount')}: {moneyFormatter(sum(chinaAfricaLoansData, d => d.amount))}
          <span style={{ display: 'inline-block', marginLeft: '1em' }}>
            {Localization.get('noOfLoans')}: {chinaAfricaLoansData.length.toLocaleString()} {Localization.get('leanAmount')}
          </span>
        </div>
      </div>
      {selectedTypes !== null ? (
        <div className="reset buButton" onClick={() => setSelectedTypes(null)}>
          {Localization.get('resetTypes')}
        </div>
      ) : null}
      <div className="graphContainer">
        <svg ref={svgRef} width={width} height={height} fontWeight="600" fontSize="0.875em">
          <g transform={`translate(${margins.left}, ${margins.top})`}>{typeRows}</g>
        </svg>
        {tooltip}
      </div>
      {lenderExplainer}
    </div>
  );
}
