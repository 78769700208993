import './styles.scss';
import { group, sum, extent } from 'd3-array';
import colors from '../colors';
import { color as d3Color } from 'd3-color';
import { useState } from 'react';
import chevron from '../images/chevron.svg';
import classNames from 'classnames';
import YearFilterGraph from './YearFilterGraph';
import TypeFilterGraph from './TypeFilterGraph';
import ReactTooltip from 'react-tooltip';
import Localization from '../Localization';
export const moneyFormatter = value => {
  let v = value;
  if (isNaN(v)) {
    console.warn('NaN');
    return 'NaN';
  }
  if (!v && v !== 0) {
    console.warn('Empty value for money formatter');
    return '';
  }
  let suffix = 'M';
  if (v > 1000) {
    v = v / 1000;
    suffix = 'B';
  }
  v = v.toFixed(1);
  return `$${v.toLocaleString()}${suffix}`;
};
function Group(props) {
  const { group, groupExpanded, setGroupExpanded } = props;

  const color = colors[group.groupKey];
  if (!color) {
    console.warn('missing color for', group.groupKey);
  }
  const tdStyle = { border: `1px solid ${color}` };
  const colord3 = d3Color(color);
  const colorWithOpacity = `rgba(${colord3.r}, ${colord3.g}, ${colord3.b}, 0.1)`;
  const darker = colord3.darker(2);
  const countryRowStyle = {
    ...tdStyle,
    backgroundColor: colorWithOpacity,
    borderBottom: 0,
    borderTop: 0,
  };
  const darkerColorWithOpacity = `rgba(${darker.r}, ${darker.g}, ${darker.b}, 0.1)`;
  const countryRowStyleDarker = {
    ...tdStyle,
    backgroundColor: darkerColorWithOpacity,
    borderBottom: 0,
    borderTop: 0,
  };
  const gapStyle = { ...tdStyle, borderBottom: 0, borderTop: 0, padding: '0.1em' };
  // const [groupExpanded, setGroupExpanded] = useState(true)
  const [countriesExpanded, setCountriesExpanded] = useState([]);

  const toggleCountry = country => () => {
    setCountriesExpanded(
      countriesExpanded.includes(country)
        ? countriesExpanded.filter(c => c !== country)
        : [...countriesExpanded, country]
    );
  };
  const toggleGroup = () => {
    setGroupExpanded(!groupExpanded);
  };

  const allExpanded = countriesExpanded.length === group.countries.length;
  const expandAllLabel = allExpanded ? Localization.get('collapseAllLists') : Localization.get('expandAllLists');

  const clickExpandAll = () => {
    if (allExpanded) {
      setCountriesExpanded([]);
    } else {
      setCountriesExpanded(group.countries.map(c => c.country));
    }
  };

  return (
    <table
      className="mainTable"
      style={{ border: `1px solid ${color}`, borderRadius: '0.5em', fontWeight: 'bold' }}
    >
      <thead>
        <tr className="widthRow">
          <td style={{ width: '6.8em' }}></td>
          <td></td>
          <td style={{ width: '3.5em' }}></td>
          <td></td>
          <td style={{ width: '8em' }}></td>
        </tr>
        <tr
          style={{ backgroundColor: color, color: '#fff', cursor: 'pointer' }}
          onClick={toggleGroup}
        >
          <td colSpan="3" style={tdStyle}>
            <img
              alt="click to expand/collapse group"
              className={classNames('chevron white', { expanded: groupExpanded })}
              src={chevron}
            />
            {Localization.get(group.groupKey, { optional: true })}
          </td>
          <td style={tdStyle}>
            {group.count} {group.count > 1 ? Localization.get('loans') : Localization.get('loan')}
          </td>
          <td style={tdStyle}>{moneyFormatter(group.amount)}</td>
        </tr>
        <tr style={{ fontWeight: '600', display: groupExpanded ? null : 'none' }}>
          <td style={tdStyle} className="expandAllButton" onClick={clickExpandAll}>
            {expandAllLabel}
          </td>
          <td colSpan="2" style={{ ...tdStyle, backgroundColor: '#d4d4d4' }}>
            {Localization.get('country')}
          </td>
          <td style={tdStyle}>{Localization.get('numOfLoans')}</td>
          <td style={tdStyle}>{Localization.get('dollarCommitted')}</td>
        </tr>
      </thead>
      {groupExpanded &&
        group.countries.map(country => {
          const expanded = countriesExpanded.includes(country.country);
          return (
            <tbody key={country.country}>
              <tr
                style={{ fontWeight: '600', cursor: 'pointer' }}
                onClick={toggleCountry(country.country)}
              >
                <td style={{ ...countryRowStyle, textAlign: 'right' }}>
                  <img
                    alt="click to expand/collapse country"
                    src={chevron}
                    className={classNames('chevron', { expanded })}
                  />
                </td>
                <td colSpan="2" style={countryRowStyleDarker}>
                  {Localization.get(country.country, { optional: true })}
                </td>
                <td style={countryRowStyle}>
                  {country.count} {country.count > 1 ? Localization.get('loans') : Localization.get('loan')}
                </td>
                <td style={countryRowStyle}>{moneyFormatter(country.amount)}</td>
              </tr>
              {expanded ? (
                <tr>
                  <td></td>
                  <td>{Localization.get('project')}</td>
                  <td>{Localization.get('year')}</td>
                  <td>{Localization.get('lender')}</td>
                  <td>{Localization.get('dollarAllocation')}</td>
                </tr>
              ) : null}
              {expanded ? (
                country.loans.map((loan, i) => {
                  return (
                    <tr key={loan['Loan ID']} className="loanRow" style={{ fontWeight: 'normal' }}>
                      <td style={tdStyle} className="alignRight">
                        {i + 1}
                      </td>
                      <td style={tdStyle}>{loan.Purpose}</td>
                      <td style={tdStyle}>{loan.Year}</td>
                      <td style={tdStyle}>
                        {Localization.get(loan['Lender'], { optional: true })}
                      </td>
                      <td style={tdStyle}>{moneyFormatter(loan.amount)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td style={gapStyle}></td>
                  <td colSpan="2" style={gapStyle}></td>
                  <td style={gapStyle}></td>
                  <td style={gapStyle}></td>
                </tr>
              )}
            </tbody>
          );
        })}
    </table>
  );
}
export default function Table(props) {
  const {
    chinaAfricaLoansData,
    type,
    unit,
    width,
    selectedYears,
    setSelectedYears,
    selectedTypes,
    setSelectedTypes,
    loansToUse,
    yearFilterLoans,
    typeFilterLoans,
    typeAccessor,
    selectedCountries,
  } = props;

  const groupByTypeCountry = Array.from(group(loansToUse, typeAccessor, d => d.Country)).map(
    group => {
      const countries = Array.from(group[1]).map(c => {
        const loans = c[1];
        loans.sort((a, b) => a.Year - b.Year);
        return {
          country: c[0],
          loans,
        };
      });

      countries.forEach(c => {
        c.count = c.loans.length;
        c.amount = sum(c.loans, d => d.amount);
      });
      countries.sort((a, b) => b[unit] - a[unit]);

      const count = countries.reduce((previous, current) => previous + current.count, 0);
      const amount = sum(countries, d => d.amount);
      return {
        groupKey: group[0],
        countries,
        count,
        amount,
      };
    }
  );

  groupByTypeCountry.sort((a, b) => b[unit] - a[unit]);

  const yearExtent = extent(chinaAfricaLoansData, d => d.Year);

  let placeLabel = Localization.get('africa')
  if(selectedCountries !== null){
    for(let i = 0; i < selectedCountries.length; i++){
      if(i === 0){
        placeLabel = Localization.get(selectedCountries[i], {optional: true})
      } else {
        placeLabel = `${placeLabel}, ${Localization.get(selectedCountries[i], {optional: true})}`
      }
    }
  }

  const yearLabel = selectedYears === null ? yearExtent.join(' – ') : selectedYears.join(', ');
  const typeLabel = selectedTypes === null ? '' : `${Localization.get(selectedTypes, {optional: true})} | `;
  const tableTitle = `${placeLabel} | ${typeLabel}${yearLabel}`;

  const [groupsExpanded, setGroupsExpanded] = useState(groupByTypeCountry.map(d => d.groupKey));

  const allExpanded = groupsExpanded.length === groupByTypeCountry.length;

  const expandAllLabel = allExpanded ? Localization.get('collapseAllLists') : Localization.get('expandAllLists');

  const clickExpandAll = () => {
    if (allExpanded) {
      setGroupsExpanded([]);
    } else {
      setGroupsExpanded(groupByTypeCountry.map(d => d.groupKey));
    }
  };

  const tableWidth = width - 64;

  const filterWidth = (tableWidth - 32) / 2;

  return (
    <>
      <div className="filters">
        <TypeFilterGraph
          typeAccessor={typeAccessor}
          width={filterWidth}
          unit={unit}
          chinaAfricaLoansData={typeFilterLoans}
          selectedTypes={selectedTypes}
          setSelectedTypes={setSelectedTypes}
          type={type}
          yearExtent={yearExtent}
        />
        <YearFilterGraph
          width={filterWidth}
          unit={unit}
          yearExtent={yearExtent}
          chinaAfricaLoansData={yearFilterLoans}
          selectedYears={selectedYears}
          setSelectedYears={setSelectedYears}
          type={type}
          typeAccessor={typeAccessor}
        />
      </div>
      <div className="loanTable">
        <div className="tableTitle">
          {tableTitle}
          <div className="expandAllButton" onClick={clickExpandAll}>
            {expandAllLabel}
          </div>
        </div>
        {groupByTypeCountry.map((group, i) => {
          const groupExpanded = groupsExpanded.includes(group.groupKey);
          const setGroupExpanded = () => {
            if (groupExpanded) {
              setGroupsExpanded(groupsExpanded.filter(d => d !== group.groupKey));
            } else {
              setGroupsExpanded([...groupsExpanded, group.groupKey]);
            }
          };
          return (
            <Group
              key={group.groupKey}
              group={group}
              type={type}
              unit={unit}
              groupExpanded={groupExpanded}
              setGroupExpanded={setGroupExpanded}
            />
          );
        })}
        <ReactTooltip className="myTooltip" effect="solid" />
      </div>
    </>
  );
}
