import './YearFilterGraph.scss'
import { group, sum, extent, range } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { moneyFormatter  } from '.'
import { useSpring, animated } from 'react-spring'
import { useState, useRef } from 'react'
import TypeFilterGraph from './TypeFilterGraph'
import Localization from '../Localization';

const AnimatedLine = (props) => {
  const {opacity, ...rest} = props
  const ySpring = useSpring({
    y: props.y
  })
  return <animated.line {...rest} style={{ opacity }} y1={ySpring.y} y2={ySpring.y} />
}
export default function YearFilterGraph(props) {
  const { selectedYears, setSelectedYears, chinaAfricaLoansData, yearExtent, unit, width,
    typeAccessor, hideInstructions
  } = props

  const groupedByYear = Array.from(group(chinaAfricaLoansData, d => d.Year)).map((group) => {
    const loans = group[1]
    return  {
      year: group[0],
      loans,
      count: loans.length,
      amount: sum(loans, d => d.amount),
    }
  })

  const margins = {
    left: 50,
    bottom: 20,
    right: 20,
    top: 2,
  }

  const xScale = scaleLinear()
    .domain([yearExtent[0], yearExtent[1] + 1])
    .range([0, width - margins.left - margins.right])

  const yExtent = extent(groupedByYear, d => unit === 'amount' ? d.amount : d.count)

  const height = 74
  const graphHeight = height - margins.top - margins.bottom
  const lineStrokeWidth = 4
  const yScale = scaleLinear()
    .domain(yExtent)
    .range([graphHeight - lineStrokeWidth / 2, lineStrokeWidth / 2])


  const years = range(yearExtent[0], yearExtent[1] + 1)
  // console.log(years)
  const yearWidth = xScale(years[1]) - xScale(years[0])
  // console.log(yearWidth)
  const clickYear = (year) => () => {
    let newYears = null
    if (selectedYears === null) {
      newYears = [year]
    } else if (selectedYears.includes(year)) {
      newYears = selectedYears.filter(d => d !== year)
      if (newYears.length === 0) {
        newYears = null
      }
    } else {
      newYears = [...selectedYears, year]
      newYears.sort()
    }
    setSelectedYears(newYears)
  }

  const [hoveredYear, setHoveredYear] = useState(null)
  const svgRef = useRef()
  const hoverYear = (year) => event => {
    if (!year) {
      setHoveredYear(null)
      return
    }
    const svgPosition = svgRef.current.getBoundingClientRect()
    const x = event.clientX - svgPosition.left
    const y = event.clientY - svgPosition.top
    setHoveredYear({
      year,
      x,
      y,
      clientY: event.clientY,

    })

  }

  let tooltip = null
  if (hoveredYear) {
    const flipX = hoveredYear.x > width / 2
    const flipY = hoveredYear.clientY > window.innerHeight * 0.75
    const tooltipStyle = {
      transform: `translate(${hoveredYear.x + (flipX ? -5 : 5)}px, ${hoveredYear.y}px) ${flipX ? 'translateX(-100%)' : ''} ${flipY ? 'translateY(-100%)' : ''}`,
    }
    //selectedTypes, setSelectedTypes, chinaAfricaLoansData, unit, width, typeAccessor
    const yearData = groupedByYear.find(d => d.year === hoveredYear.year)
    if (yearData) {
      tooltip = (
        <div className='yearFilterGraphTooltip tooltip' style={tooltipStyle}>
          <div className='tooltipHeader'>
            <div className='instruction'>
              {Localization.getWithArguments('clickToSelectAndUpdateMapDisplay', hoveredYear.year)}
            </div>
            <div className='tooltip__title'>{hoveredYear.year}</div>
            <div  style={{ fontSize: '0.875em', fontWeight: '600'}}>
            {Localization.get('amount')}: {moneyFormatter(yearData.amount)}
              <span style={{ display: 'inline-block', marginLeft: '1em'}}>{Localization.get('noOfLoans')}: {yearData.count} {Localization.get('leanAmount')}</span>
            </div>
          </div>
          <div className='tooltipContent'>
            <TypeFilterGraph 
              selectedTypes={null} 
              chinaAfricaLoansData={yearData.loans}
              width={300} 
              unit={unit} 
              typeAccessor={typeAccessor} 
              type={props.type}
              inTooltip
              hideInstructions
              />
          </div>
        </div>
      )
    }
  }

  const yearRows = years.map((year, yearIndex) => {
    const row = groupedByYear.find(d => +d.year === +year)
    let amount = row ? row.amount : 0
    let count = row ? row.count : 0
    const value = unit === 'amount' ? amount : count
    const x = xScale(year)
    const y = yScale(value)

    let lineOpacity = selectedYears === null ? 1 : selectedYears.includes(year) ? 1 : 0.2
    if (hoveredYear && hoveredYear.year !== year) {
      lineOpacity = lineOpacity === 0.2 ? 0.1 : (selectedYears !== null ? 0.5 : 0.2)
    } else if (hoveredYear && hoveredYear.year === year) {
      lineOpacity = 1
    }
    return (
      <g key={year}
        style={{ cursor: 'pointer' }}
        transform={`translate(${x}, 0)`} onClick={clickYear(year)} onMouseMove={hoverYear(year)} onMouseOver={hoverYear(year)} onMouseOut={hoverYear(null)}>
        <rect width={yearWidth} height={graphHeight} fill='#EBEBE6' opacity={yearIndex % 2 === 0 ? '0.5' : null} />
        {value !== 0 ? <AnimatedLine opacity={lineOpacity} y={y} stroke='#cc0000' x2={yearWidth} strokeWidth={lineStrokeWidth} /> : null }
        {year % 2 === 0 ? <text x={yearWidth / 2} dy='1em' y={graphHeight} textAnchor='middle'>{year}</text> : null}
      </g>

    )
  })

  const yLabels = yExtent.map((yValue, i) => {
    const y = yScale(yValue)
    const label = unit === 'amount' ? moneyFormatter(yValue) : yValue
    return (
      <g key={`${yValue}-${i}`} transform={`translate(0, ${y})`}>
        <text dy='0.5em' fill='#707070' textAnchor='end' x='-5'>{label}</text>
      </g>
    )
  })

  return (
    <div className='YearFilterGraph'>
      <div style={{ lineHeight: '1.5em', marginBottom: '0.5em', display: hideInstructions ? 'none' : null}}>
        <span style={{ fontWeight: 'bold' }}>{Localization.get('years')}</span> <span className='instruction'>{Localization.get('selectOneOrMoreYearsToUpdate')}</span>
        <br />
        <div style={{ fontSize: '0.875em', fontWeight: '600'}}>
          {Localization.get('amount')}: {moneyFormatter(sum(chinaAfricaLoansData, d => d.amount))}
          <span style={{ display: 'inline-block', marginLeft: '1em'}}>
            {Localization.get('noOfLoans')}: {chinaAfricaLoansData.length.toLocaleString()} {Localization.get('leanAmount')}
          </span>
        </div>
      </div>
      {selectedYears !== null ? <div className='reset buButton' onClick={() => setSelectedYears(null)}>{Localization.get('resetYears')}</div> : null }
      <div className='graphContainer'>
        <svg ref={svgRef} width={width} height={height} fontWeight='600' fontSize='0.875em'>
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            {yearRows}
            {yLabels}
          </g>
        </svg>
        {tooltip}
      </div>
    </div>
  )
}
