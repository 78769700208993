import { format } from 'd3-format';
import lightColors, { darkColors } from './colors';
export const formatAs = {
  number: format(',d'),
  percent: format('.0%'),
};

export const geoKey = {
  regions: d => d.region,
  countries: d => d.country,
  states: d => d.stateID,
};

export const colors = {
};

Object.keys(lightColors).forEach(key => {
  colors[key] = {
    light: lightColors[key],
    dark: darkColors[key],
  };
})

export const locations = {
  states: {
    "Albacete-Spain": { latitude: 38.994349, longitude: -1.8585424 },
    "Coimbra-Portugal": { latitude: 40.2033145, longitude: -8.4102573 },
    "Santa-Cruz-Argentina": { latitude: -51.6352821, longitude: -69.2474353 },
    "San-Juan-Argentina": { latitude: -31.5287127, longitude: -68.5360403 },
    "Jujuy-Argentina": { latitude: -24.1857864, longitude: -65.2994767 },
    "East-Ayrshire-United-Kingdom": { latitude: 55.4518496, longitude: -4.2644478 },
    "SC-Brazil": { latitude: -27.2423392, longitude: -50.2188556 },
    "AGS-Mexico": { latitude: 21.8853247, longitude: -102.2915131 },
    "Melaka-Malaysia": { latitude: 2.189594, longitude: 102.2500868 },
    "Vila-Real-Portugal": { latitude: 41.3010351, longitude: -7.7422354 },
    "Viseu-Portugal": { latitude: 40.7276419, longitude: -7.9157099 },
    "MG-Brazil": { latitude: -18.512178, longitude: -44.5550308 },
    "SP-Brazil": { latitude: -23.5505199, longitude: -46.6333094 },
    "Maharashtra-India": { latitude: 19.7514798, longitude: 75.7138884 },
    "Dhaka-Bangladesh": { latitude: 23.810332, longitude: 90.4125181 },
    "Aomori-Japan": { latitude: 40.8222197, longitude: 140.7473524 },
    "-Italy": { latitude: 41.87194, longitude: 12.56738 },
    "Ferrandina-Italy": { latitude: 40.4984557, longitude: 16.4570531 },
    "County-Kilkenny-Ireland": { latitude: 52.5776957, longitude: -7.218002 },
    "North-Sulawesi-Indonesia": { latitude: 0.6246932, longitude: 123.9750018 },
    "Cumbria-United-Kingdom": { latitude: 54.5772323, longitude: -2.7974835 },
    "Gangwon-do-Republic-of-Korea": { latitude: 37.8228, longitude: 128.1555 },
    "Jeju-do-Republic-of-Korea": { latitude: 33.4890113, longitude: 126.4983023 },
    "Osmaniye-Turkey": { latitude: 37.0746279, longitude: 36.2464002 },
    "RS-Brazil": { latitude: -30.0346316, longitude: -51.2176986 },
    "Minsk-Belarus": { latitude: 53.9006011, longitude: 27.558972 },
    "Brest-Belarus": { latitude: 52.0996507, longitude: 23.7636662 },
    "RN-Brazil": { latitude: -5.4025803, longitude: -36.954107 },
    "GTO-Mexico": { latitude: 20.9170187, longitude: -101.1617356 },
    "AB-Canada": { latitude: 53.9332706, longitude: -116.5765035 },
    "County-Wexford-Ireland": { latitude: 52.4793603, longitude: -6.5839913 },
    "Rajasthan-India": { latitude: 27.0238036, longitude: 74.2179326 },
    "Vitebsk-Belarus": { latitude: 55.1926809, longitude: 30.206359 },
    "Santa-Cruz-Bolivia": { latitude: -17.8145819, longitude: -63.1560853 },
    "Srpska-Bosnia-and-Herzegovina": { latitude: 44.7280186, longitude: 17.3148136 },
    "Cuenca-Spain": { latitude: 40.0703925, longitude: -2.1374162 },
    "CA-United-States": { latitude: 36.778261, longitude: -119.4179324 },
    "Highland-United-Kingdom": { latitude: 57.4595749, longitude: -4.2263955 },
    "NJ-United-States": { latitude: 40.0583238, longitude: -74.4056612 },
    "Adamaoua-Cameroon": { latitude: 6.9181954, longitude: 12.8054753 },
    "Sindh-Pakistan": { latitude: 25.8943018, longitude: 68.5247149 },
    "NSW-Australia": { latitude: -31.2532183, longitude: 146.921099 },
    "Tuzlanski-Bosnia-and-Herzegovina": { latitude: 44.5374611, longitude: 18.6734687 },
    "CE-Brazil": { latitude: -5.4983977, longitude: -39.3206241 },
    "Denbighshire-(Wales)-United-Kingdom": { latitude: 53.1842288, longitude: -3.4224985 },
    "AP-Brazil": { latitude: 0.9019925, longitude: -52.0029565 },
    "Somerset-United-Kingdom": { latitude: 51.105097, longitude: -2.9262307 },
    "VIC-Australia": { latitude: -37.4713077, longitude: 144.7851531 },
    "Castelo-Branco-Portugal": { latitude: 39.8031161, longitude: -7.4597725 },
    "SA-Australia": { latitude: -30.0002315, longitude: 136.2091547 },
    "QLD-Australia": { latitude: -20.9175738, longitude: 142.7027956 },
    "CAMP-Mexico": { latitude: 43.4975163, longitude: -76.2511494 },
    "Madhya-Pradesh-India": { latitude: 22.9734229, longitude: 78.6568942 },
    "Kampong-Speu-Cambodia": { latitude: 11.4650455, longitude: 104.5072722 },
    "Rajshahi-Bangladesh": { latitude: 24.3745146, longitude: 88.604166 },
    "Stara-Zagora-Bulgaria": { latitude: 42.4257769, longitude: 25.6344644 },
    "County-Antrim-United-Kingdom": { latitude: 54.7192841, longitude: -6.2082351 },
    "Kachin-Myanmar": { latitude: 25.850904, longitude: 97.4381355 },
    "TAS-Australia": { latitude: -41.4545196, longitude: 145.9706647 },
    "Kampot-Cambodia": { latitude: 10.5942421, longitude: 104.1640355 },
    "VI-Region-Chile": { latitude: -34.5755374, longitude: -71.0022311 },
    "Balochistan-Pakistan": { latitude: 28.4907332, longitude: 65.0957792 },
    "Dorset-United-Kingdom": { latitude: 50.7487635, longitude: -2.3444786 },
    "Pursat-Cambodia": { latitude: 12.4651824, longitude: 103.8911999 },
    "Koh-Kong-Cambodia": { latitude: 11.5762804, longitude: 103.3587288 },
    "Devon-United-Kingdom": { latitude: 50.7155591, longitude: -3.530875 },
    "CHIH-Mexico": { latitude: 28.4854458, longitude: -105.7820674 },
    "Tottori-Japan": { latitude: 35.5011082, longitude: 134.2351011 },
    "Sud-Cameroon": { latitude: 2.7202832, longitude: 11.7068294 },
    "County-Kerry-Ireland": { latitude: 52.1544607, longitude: -9.5668633 },
    "V-Region-Chile": { latitude: -32.5040172, longitude: -71.0022311 },
    "PR-Brazil": { latitude: -25.2520888, longitude: -52.0215415 },
    "Essex-United-Kingdom": { latitude: 51.5742447, longitude: 0.4856781 },
    "County-Tyrone-United-Kingdom": { latitude: 54.6418158, longitude: -6.7443895 },
    "North-Cape-South-Africa": { latitude: -29.0466808, longitude: 21.8568586 },
    "Chungcheongbuk-do-Republic-of-Korea": { latitude: 36.8, longitude: 127.7 },
    "N'Djamena-Chad": { latitude: 12.1348457, longitude: 15.0557415 },
    "Sucumbios-Ecuador": { latitude: 0.0889231, longitude: -76.8897557 },
    "ON-Canada": { latitude: 51.253775, longitude: -85.323214 },
    "Prahova-Romania": { latitude: 45.0891906, longitude: 26.0829313 },
    "-Malta": { latitude: 35.937496, longitude: 14.375416 },
    "I-Region-Chile": { latitude: -20.2028799, longitude: -69.2877535 },
    "Odessa-Ukraine": { latitude: 46.482526, longitude: 30.7233095 },
    "Pool-Congo": { latitude: -3.7762628, longitude: 14.8454619 },
    "Tra-Vinh-Vietnam": { latitude: 9.812741, longitude: 106.2992912 },
    "YUC-Mexico": { latitude: 20.7098786, longitude: -89.0943377 },
    "County-Tipperary-Ireland": { latitude: 52.4737894, longitude: -8.1618514 },
    "Lualaba-Congo,-Dem.-Rep.": { latitude: -9.459667, longitude: 23.0011989 },
    "Kongo-Central-Congo,-Dem.-Rep.": { latitude: -5.2365685, longitude: 13.914399 },
    "Bas-Sassandra-Cote-d'Ivoire": { latitude: 5.3567916, longitude: -6.7493993 },
    "Artemisa-Cuba": { latitude: 22.8159074, longitude: -82.7589859 },
    "Central-Ghana": { latitude: 5.5607846, longitude: -1.0586135 },
    "Napo-Ecuador": { latitude: -0.9955964, longitude: -77.8129684 },
    "Nottinghamshire-United-Kingdom": { latitude: 53.100319, longitude: -0.9936306 },
    "Azuay-Ecuador": { latitude: -2.8943068, longitude: -78.9968344 },
    "Zamora-Chinchipe-Ecuador": { latitude: -4.0655892, longitude: -78.9503525 },
    "Esmeraldas-Ecuador": { latitude: 0.9681789, longitude: -79.6517202 },
    "Canar-Ecuador": { latitude: -2.5589315, longitude: -78.9388191 },
    "Hainaut-Belgium": { latitude: 50.5257076, longitude: 4.0621017 },
    "County-Leitrim-Ireland": { latitude: 54.1246909, longitude: -8.0020132 },
    "MS-Brazil": { latitude: -20.7722295, longitude: -54.7851531 },
    "Guayas-Ecuador": { latitude: -1.9574839, longitude: -79.9192702 },
    "Oxfordshire-United-Kingdom": { latitude: 51.7612056, longitude: -1.2464674 },
    "Loja-Ecuador": { latitude: -4.0078909, longitude: -79.2112769 },
    "Northamptonshire-United-Kingdom": { latitude: 52.2729944, longitude: -0.8755515 },
    "Djibloho-Equatorial-Guinea": { latitude: 1.5890407, longitude: 10.8225732 },
    "Kogi-Nigeria": { latitude: 7.7337325, longitude: 6.6905836 },
    "AZ-United-States": { latitude: 34.0489281, longitude: -111.0937311 },
    "Cambridgeshire-United-Kingdom": { latitude: 52.2052973, longitude: 0.1218195 },
    "Gunma-Japan": { latitude: 36.5605388, longitude: 138.8799972 },
    "Karnataka-India": { latitude: 15.3172775, longitude: 75.7138884 },
    "Oromia-Ethiopia": { latitude: 7.5460377, longitude: 40.6346851 },
    "Somali-NRG-Ethiopia": { latitude: 6.737888, longitude: 44.280378 },
    "Northumberland-United-Kingdom": { latitude: 55.2082542, longitude: -2.0784138 },
    "Misraq-Shewa-Ethiopia": { latitude: 7.3577808, longitude: 38.4849923 },
    "Dubai-United-Arab-Emirates": { latitude: 25.2048493, longitude: 55.2707828 },
    "Somali-Ethiopia": { latitude: 6.6612293, longitude: 43.7908453 },
    "Viti-Levu-Fiji": { latitude: -17.8483187, longitude: 178.0118473 },
    "Haut-Ogooue-Gabon": { latitude: -1.4762544, longitude: 13.914399 },
    "Orellana-Ecuador": { latitude: -0.4545163, longitude: -76.9950286 },
    "Northern-Ghana": { latitude: 9.5439269, longitude: -0.9056623 },
    "NC-United-States": { latitude: 35.7595731, longitude: -79.0192997 },
    "Dubreka-Guinea": { latitude: 9.7907348, longitude: -13.5147735 },
    "Mazowieckie-Poland": { latitude: 51.8927182, longitude: 21.0021679 },
    "Uttar-Pradesh-India": { latitude: 26.8467088, longitude: 80.9461592 },
    "Haryana-India": { latitude: 29.0587757, longitude: 76.085601 },
    "Plovdiv-Bulgaria": { latitude: 42.1354079, longitude: 24.7452904 },
    "Atyrau-Kazakhstan": { latitude: 47.0944959, longitude: 51.9238373 },
    "Dolnoslaskie-Poland": { latitude: 51.1339861, longitude: 16.8841961 },
    "West-Java-Indonesia": { latitude: -7.090911, longitude: 107.668887 },
    "Negeri-Sembilan-Malaysia": { latitude: 2.7258058, longitude: 101.9423782 },
    "East-Java-Indonesia": { latitude: -7.5360639, longitude: 112.2384017 },
    "Central-Java-Indonesia": { latitude: -7.150975, longitude: 110.1402594 },
    "Punjab-Pakistan": { latitude: 31.1704063, longitude: 72.7097161 },
    "Banten-Indonesia": { latitude: -6.4058172, longitude: 106.0640179 },
    "Aceh-Indonesia": { latitude: 4.695135, longitude: 96.7493993 },
    "Riau-Indonesia": { latitude: 0.2933469, longitude: 101.7068294 },
    "West-Kalimantan-Indonesia": { latitude: -0.2787808, longitude: 111.4752851 },
    "Bali-Indonesia": { latitude: -8.3405389, longitude: 115.0919509 },
    "North-Sumatra-Indonesia": { latitude: 2.1153547, longitude: 99.5450974 },
    "South-Sumatra-Indonesia": { latitude: -3.3194374, longitude: 103.914399 },
    "West-Sumatra-Indonesia": { latitude: -0.7399397, longitude: 100.8000051 },
    "Kakheti-Georgia": { latitude: 41.6481602, longitude: 45.6905554 },
    "South-Sulawesi-Indonesia": { latitude: -3.6687994, longitude: 119.9740534 },
    "Blagoevgrad-Bulgaria": { latitude: 42.0208569, longitude: 23.0943385 },
    "Western-Ghana": { latitude: 5.3901978, longitude: -2.1450245 },
    "Warminsko-Mazurskie-Poland": { latitude: 53.8671117, longitude: 20.7027861 },
    "Jambi-Indonesia": { latitude: -1.6101229, longitude: 103.6131203 },
    "Sylhet-Bangladesh": { latitude: 24.8949294, longitude: 91.8687063 },
    "Selangor-Malaysia": { latitude: 3.0738379, longitude: 101.5183469 },
    "Southeast-Sulawesi-Indonesia": { latitude: -4.14491, longitude: 122.174605 },
    "Bengkulu-Indonesia": { latitude: -3.7928451, longitude: 102.2607641 },
    "Central-Sulawesi-Indonesia": { latitude: -1.4300254, longitude: 121.4456179 },
    "Karak-Jordan": { latitude: 31.1853497, longitude: 35.7047733 },
    "Almaty-Kazakhstan": { latitude: 43.2220146, longitude: 76.8512485 },
    "BC-Mexico": { latitude: 30.8406338, longitude: -115.2837585 },
    "Kujawsko-Pomorskie-Poland": { latitude: 53.1648363, longitude: 18.4834224 },
    "Lop-Buri-Thailand": { latitude: 14.7995081, longitude: 100.6533706 },
    "Stung-Treng-Cambodia": { latitude: 13.576473, longitude: 105.9699878 },
    "Rhondda-Cynon-Taf-(Wales)-United-Kingdom": { latitude: 51.6490207, longitude: -3.4288692 },
    "ZH-Netherlands": { latitude: 52.0207975, longitude: 4.4937836 },
    "Gujarat-India": { latitude: 22.258652, longitude: 71.1923805 },
    "Pavlodar-Kazakhstan": { latitude: 52.2873032, longitude: 76.9674023 },
    "Faro-Portugal": { latitude: 37.0193548, longitude: -7.9304397 },
    "Garissa-County-Kenya": { latitude: -0.4532293, longitude: 39.6460988 },
    "Norrbotten-Sweden": { latitude: 66.8309216, longitude: 20.3991966 },
    "Bishkek-Kyrgyzstan": { latitude: 42.8746212, longitude: 74.5697617 },
    "Wielkopolskie-Poland": { latitude: 52.279986, longitude: 17.3522939 },
    "Vientiane-Laos": { latitude: 17.9757058, longitude: 102.6331035 },
    "Kumamoto-Japan": { latitude: 32.8032164, longitude: 130.7079369 },
    "PB-Brazil": { latitude: -7.2399609, longitude: -36.7819505 },
    "Saravan-Laos": { latitude: 15.7165658, longitude: 106.4140868 },
    "Luang-Prabang-Laos": { latitude: 19.883285, longitude: 102.1387166 },
    "Phongsali-Laos": { latitude: 21.6818943, longitude: 102.1089679 },
    "SH-Germany": { latitude: 54.2193672, longitude: 9.6961167 },
    "Xaisomboun-Laos": { latitude: 18.8970015, longitude: 103.0918897 },
    "Xaignabouri-Laos": { latitude: 19.3907886, longitude: 101.5248055 },
    "Quang-Ninh-Vietnam": { latitude: 21.006382, longitude: 107.2925144 },
    "County-Derry-United-Kingdom": { latitude: 54.9690778, longitude: -7.1958351 },
    "Doubs-France": { latitude: 46.9276, longitude: 6.349001 },
    "Luang-Namtha-Laos": { latitude: 20.9709135, longitude: 101.4112049 },
    "Southern-Malawi": { latitude: -15.4201189, longitude: 35.0388164 },
    "Kayes-Mali": { latitude: 14.4367876, longitude: -11.4452717 },
    "Gao-Mali": { latitude: 16.2639807, longitude: -0.0279867 },
    "L'Oriental-Morocco": { latitude: 33.4198879, longitude: -2.1450245 },
    "Ulcinj-Montenegro": { latitude: 41.9310884, longitude: 19.2147632 },
    "ACT-Australia": { latitude: -35.4734679, longitude: 149.0123679 },
    "MT-United-States": { latitude: 46.8796822, longitude: -110.3625658 },
    "Mandalay-Myanmar": { latitude: 21.9588282, longitude: 96.0891032 },
    "Province-No-3-Nepal": { latitude: 27.6858556, longitude: 85.3147318 },
    "Oudomxai-Laos": { latitude: 20.4921929, longitude: 101.8891721 },
    "Province-No-4-Nepal": { latitude: 28.2048975, longitude: 83.9731271 },
    "Ogun-Nigeria": { latitude: 6.9979747, longitude: 3.4737378 },
    "Xiengkhouang-Laos": { latitude: 19.6093003, longitude: 103.7289167 },
    "Ondo-Nigeria": { latitude: 7.1000051, longitude: 4.8416936 },
    "Niger-Nigeria": { latitude: 17.607789, longitude: 8.081666 },
    "Taraba-Nigeria": { latitude: 7.9993616, longitude: 10.7739863 },
    "AJK-Pakistan": { latitude: 33.9259055, longitude: 73.7810334 },
    "WA-Australia": { latitude: -27.6728168, longitude: 121.6283098 },
    "Leiria-Portugal": { latitude: 39.7495331, longitude: -8.807683 },
    "Eastern-Nile-South-Sudan": { latitude: 9.8894202, longitude: 32.7181375 },
    "Khyber-Pakhtunkhwa-Pakistan": { latitude: 34.9526205, longitude: 72.331113 },
    "Barisal-Bangladesh": { latitude: 22.7010021, longitude: 90.3534511 },
    "Burgas-Bulgaria": { latitude: 42.5047926, longitude: 27.4626361 },
    "Porto-Portugal": { latitude: 41.1579438, longitude: -8.6291053 },
    "Central-Papua-New-Guinea": { latitude: -9.1360187, longitude: 147.4627259 },
    "Puno-Peru": { latitude: -15.8402218, longitude: -70.0218805 },
    "Bur-Said-Egypt": { latitude: 31.2652893, longitude: 32.3018661 },
    "Bataan-(Central-Luzon)-Philippines": { latitude: 14.6416842, longitude: 120.4818446 },
    "Branicevo-Serbia": { latitude: 44.6982246, longitude: 21.5446775 },
    "IV-Region-Chile": { latitude: -30.540181, longitude: -70.8119953 },
    "KwaZulu-Natal-South-Africa": { latitude: -28.5305539, longitude: 30.8958242 },
    "Mpumalanga-South-Africa": { latitude: -25.565336, longitude: 30.5279096 },
    "Sabugal-Portugal": { latitude: 40.3793416, longitude: -7.0498442 },
    "Madang-Papua-New-Guinea": { latitude: -5.2218841, longitude: 145.7856324 },
    "TX-United-States": { latitude: 31.9685988, longitude: -99.9018131 },
    "Cheshire-United-Kingdom": { latitude: 53.2326344, longitude: -2.6103158 },
    "Yorkshire-United-Kingdom": { latitude: 53.9915028, longitude: -1.5412015 },
    "Lubuskie-Poland": { latitude: 52.2274612, longitude: 15.2559103 },
    "GO-Brazil": { latitude: -15.8270369, longitude: -49.8362237 },
    "PA-Brazil": { latitude: -1.9981271, longitude: -54.9306152 },
    "Limpopo-South-Africa": { latitude: -23.4012946, longitude: 29.4179324 },
    "Beja-Portugal": { latitude: 38.0153039, longitude: -7.8627308 },
    "Shan-Myanmar": { latitude: 22.0361985, longitude: 98.1338558 },
    "Krong-Preah-Sihanouk-Cambodia": { latitude: 10.6267867, longitude: 103.5115545 },
    "Jeollanam-do-Republic-of-Korea": { latitude: 34.8679, longitude: 126.991 },
    "Lincolnshire-United-Kingdom": { latitude: 52.9451889, longitude: -0.1601246 },
    "Loei-Thailand": { latitude: 17.4860232, longitude: 101.7223002 },
    "North-Western-Sri-Lanka": { latitude: 7.7584091, longitude: 80.1875065 },
    "Central-Sri-Lanka": { latitude: 7.2564996, longitude: 80.7214417 },
    "As-Suways-Egypt": { latitude: 29.9668343, longitude: 32.5498069 },
    "Southern-Sri-Lanka": { latitude: 6.237375, longitude: 80.543845 },
    "Uva-Sri-Lanka": { latitude: 6.8427612, longitude: 81.3399414 },
    "Bahr-el-Ghazal-Sudan": { latitude: 9.3180585, longitude: 29.8508835 },
    "-Singapore": { latitude: 1.352083, longitude: 103.819836 },
    "Khartoum-Sudan": { latitude: 15.5006544, longitude: 32.5598994 },
    "Dushanbe-Tajikistan": { latitude: 38.5597722, longitude: 68.7870384 },
    "Maritime-Togo": { latitude: 6.4913504, longitude: 1.2891036 },
    "Kyandongo-Uganda": { latitude: 1.9530448, longitude: 32.1391217 },
    "Kamuli-Uganda": { latitude: 0.9187107, longitude: 33.1239049 },
    "Yangon-Myanmar": { latitude: 16.840939, longitude: 96.173526 },
    "Tamil-Nadu-India": { latitude: 11.1271225, longitude: 78.6568942 },
    "Tashkent-Uzbekistan": { latitude: 41.2994958, longitude: 69.2400734 },
    "Thai-Nguyen-Vietnam": { latitude: 21.5671559, longitude: 105.8252038 },
    "Hai-Phong-Vietnam": { latitude: 20.8449115, longitude: 106.6880841 },
    "Manche-France": { latitude: 49.114712, longitude: -1.3115949 },
    "Brasov-Romania": { latitude: 45.6579755, longitude: 25.6011977 },
    "Binh-Thuan-Vietnam": { latitude: 11.0903703, longitude: 108.0720781 },
    "Ha-Tinh-Vietnam": { latitude: 18.2943776, longitude: 105.6745247 },
    "Metro-Manila-(NCR)-Philippines": { latitude: 14.5995124, longitude: 120.9842195 },
    "Thai-Binh-Vietnam": { latitude: 20.5386936, longitude: 106.3934777 },
    "Hai-Duong-Vietnam": { latitude: 20.9373413, longitude: 106.3145542 },
    "Mykolaiv-Ukraine": { latitude: 46.975033, longitude: 31.9945829 },
    "Southern-Zambia": { latitude: -16.9620634, longitude: 26.419389 },
    "Yamaguchi-Japan": { latitude: 34.2796769, longitude: 131.5212742 },
    "Yaroslav-Oblast-Russia": { latitude: 57.8991523, longitude: 38.8388633 },
    "Mashonaland-West-Zimbabwe": { latitude: -17.4851029, longitude: 29.7889248 },
    "Aktobe-Kazakhstan": { latitude: 50.2839339, longitude: 57.166978 },
    "Matabeleland-North-Zimbabwe": { latitude: -18.5331566, longitude: 27.5495846 },
    "-Cuba": { latitude: 21.521757, longitude: -77.781167 },
    "Cornwall-United-Kingdom": { latitude: 50.2660471, longitude: -5.0527125 },
    "Chittagong-Bangladesh": { latitude: 22.358761, longitude: 91.782147 },
    "Banjul-Gambia": { latitude: 13.454676, longitude: -16.579137 },
    "Salta-Argentina": { latitude: -24.782217, longitude: -65.423462 },
    "North-Western-Zambia": { latitude: -13.056460, longitude: 25.371601 },
    "SON-Mexico": { latitude: 29.084489, longitude: -110.959159 },
    "Morbihan-France": { latitude: 47.898096, longitude: -2.839328 },
    "Kayin-Myanmar": { latitude: 11.811529, longitude: 98.719277 },
    "Kaafu-Maldives": { latitude: 4.374125, longitude: 73.650870 },
    "North-Kalimantan-Indonesia": { latitude: 3.073743, longitude: 116.041272 },
    "South-Kazakhstan-Kazakhstan": { latitude: 43.010115, longitude: 68.752305 },
    "Ayeyawady-Myanmar": { latitude: 16.900152, longitude: 95.177885 },
    "Tigray-Ethiopia": { latitude: 14.110769, longitude: 38.726527 },
    "Khulna-Bangladesh": { latitude: 22.845293, longitude: 89.540906 },
    "Midlands-Zimbabwe": { latitude: -19.452529, longitude: 29.809732 },
    "Abu-Dhabi-United-Arab-Emirates": { latitude: 24.400253, longitude: 54.525928 },
    "Rangpur-Bangladesh": { latitude: 25.743733, longitude: 89.275304 },
    "Savanes-Cote-d'Ivoire": { latitude: 9.455572, longitude: -5.632898 },
    "Kedah-Malaysia": { latitude: 6.001073, longitude: 100.617450 },
    "JAL-Mexico": { latitude: 20.656157, longitude: -103.351960 },
    "ZAC-Mexico": { latitude: 23.174460, longitude: -102.869235 },
    "Eastern-Highlands-Papua-New-Guinea": { latitude: -6.364150, longitude: 145.817638 },
    "MS-United-States": { latitude: 32.315350, longitude: -90.080630 },
    "Waikato-New-Zealand": { latitude: -37.908689, longitude: 175.502367 },
    "Pest-Hungary": { latitude: 47.412302, longitude: 19.363510 },
    "PE-Brazil": { latitude: -8.372399, longitude: -38.062582 },
    "Saravane-Laos": { latitude: 15.716912, longitude: 106.414098 },
    "Adana-Turkey": { latitude: 37.022396, longitude: 35.357088 },
    "BA-Brazil": { latitude: -12.258851, longitude: -41.491341 },
    "PI-Brazil": { latitude: -7.575452, longitude: -42.573121 },
    "MT-Brazil": { latitude: -12.850392, longitude: -55.833401 },
    "ES-Brazil": { latitude: -19.209352, longitude: -40.233306 },
    "TO-Brazil": { latitude: -10.748407, longitude: -48.291630 },
  },
  countries: {
    "Afghanistan": { latitude: 33.93911, longitude: 67.709953 },
    "Albania": { latitude: 41.153332, longitude: 20.168331 },
    "Algeria": { latitude: 28.033886, longitude: 1.659626 },
    "Angola": { latitude: -11.202692, longitude: 17.873887 },
    "Antigua and Barbuda": { latitude: 17.060816, longitude: -61.796428 },
    "Argentina": { latitude: -38.416097, longitude: -63.616672 },
    "Australia": { latitude: -25.274398, longitude: 133.775136 },
    "Austria": { latitude: 47.516231, longitude: 14.550072 },
    "Bahamas": { latitude: 25.03428, longitude: -77.39628 },
    "Bangladesh": { latitude: 23.684994, longitude: 90.356331 },
    "Barbados": { latitude: 13.193887, longitude: -59.543198 },
    "Belarus": { latitude: 53.709807, longitude: 27.953389 },
    "Belgium": { latitude: 50.503887, longitude: 4.469936 },
    "Belize": { latitude: 17.189877, longitude: -88.49765 },
    "Benin": { latitude: 9.30769, longitude: 2.315834 },
    "Bolivia": { latitude: -16.290154, longitude: -63.588653 },
    "Bosnia and Herzegovina": { latitude: 43.915886, longitude: 17.679076 },
    "Botswana": { latitude: -22.328474, longitude: 24.684866 },
    "Brazil": { latitude: -14.235004, longitude: -51.92528 },
    "Bulgaria": { latitude: 42.733883, longitude: 25.48583 },
    "Burkina Faso": { latitude: 12.238333, longitude: -1.561593 },
    "Cambodia": { latitude: 12.565679, longitude: 104.990963 },
    "Cameroon": { latitude: 7.369722, longitude: 12.354722 },
    "Canada": { latitude: 56.130366, longitude: -106.346771 },
    "Chad": { latitude: 15.454166, longitude: 18.732207 },
    "Chile": { latitude: -35.675147, longitude: -71.542969 },
    "China": { latitude: 35.86166, longitude: 104.195397 },
    "Colombia": { latitude: 4.570868, longitude: -74.297333 },
    "Congo": { latitude: -4.038333, longitude: 21.758664 },
    "Congo, Dem. Rep.": { latitude: -4.038333, longitude: 21.758664 },
    "Costa Rica": { latitude: 9.748917, longitude: -83.753428 },
    "Cote d'Ivoire": { latitude: 7.539989, longitude: -5.54708 },
    "Croatia": { latitude: 45.1, longitude: 15.2000001 },
    "Cuba": { latitude: 21.521757, longitude: -77.781167 },
    "Czech Republic": { latitude: 49.817492, longitude: 15.472962 },
    "Denmark": { latitude: 56.26392, longitude: 9.501785 },
    "Dominica": { latitude: 15.414999, longitude: -61.370976 },
    "Dominican Republic": { latitude: 18.735693, longitude: -70.162651 },
    "Ecuador": { latitude: -1.831239, longitude: -78.183406 },
    "Egypt": { latitude: 26.820553, longitude: 30.802498 },
    "El Salvador": { latitude: 13.794185, longitude: -88.89653 },
    "Equatorial Guinea": { latitude: 1.650801, longitude: 10.267895 },
    "Estonia": { latitude: 58.595272, longitude: 25.0136071 },
    "Ethiopia": { latitude: 9.145, longitude: 40.489673 },
    "Fiji": { latitude: -17.713371, longitude: 178.065032 },
    "Finland": { latitude: 61.92411, longitude: 25.7481511 },
    "France": { latitude: 46.227638, longitude: 2.213749 },
    "Gabon": { latitude: -0.803689, longitude: 11.609444 },
    "Gambia": { latitude: 13.454698, longitude: -16.579130 },
    "Georgia": { latitude: 43.513873, longitude: 42.174668 },
    "Germany": { latitude: 51.165691, longitude: 10.451526 },
    "Ghana": { latitude: 7.946527, longitude: -1.023194 },
    "Greece": { latitude: 39.074208, longitude: 21.824312 },
    "Grenada": { latitude: 12.1165, longitude: -61.679 },
    "Guatemala": { latitude: 15.783471, longitude: -90.230759 },
    "Guinea": { latitude: 9.945587, longitude: -9.696645 },
    "Guyana": { latitude: 4.860416, longitude: -58.93018 },
    "Honduras": { latitude: 15.199999, longitude: -86.241905 },
    "Hong Kong SAR, China": { latitude: 22.3193039, longitude: 114.1693611 },
    "Hungary": { latitude: 47.162494, longitude: 19.5033041 },
    "India": { latitude: 20.593684, longitude: 78.96288 },
    "Indonesia": { latitude: -0.789275, longitude: 113.921327 },
    "Iran": { latitude: 32.427908, longitude: 53.688046 },
    "Ireland": { latitude: 53.1423672, longitude: -7.6920536 },
    "Italy": { latitude: 41.87194, longitude: 12.56738 },
    "Jamaica": { latitude: 18.109581, longitude: -77.297508 },
    "Japan": { latitude: 36.204824, longitude: 138.252924 },
    "Jordan": { latitude: 30.585164, longitude: 36.238414 },
    "Kazakhstan": { latitude: 48.019573, longitude: 66.923684 },
    "Kenya": { latitude: -0.023559, longitude: 37.906193 },
    "Kyrgyzstan": { latitude: 41.20438, longitude: 74.766098 },
    "Laos": { latitude: 19.85627, longitude: 102.495496 },
    "Lebanon": { latitude: 33.854721, longitude: 35.862285 },
    "Liberia": { latitude: 6.428055, longitude: -9.429499 },
    "Macedonia, FYR": { latitude: 41.608635, longitude: 21.745275 },
    "Madagascar": { latitude: -18.766947, longitude: 46.869107 },
    "Malawi": { latitude: -13.254308, longitude: 34.301525 },
    "Malaysia": { latitude: 4.210484, longitude: 101.975766 },
    "Maldives": { latitude: 4.175286, longitude: 73.509114 },
    "Mali": { latitude: 17.570692, longitude: -3.996166 },
    "Malta": { latitude: 35.937496, longitude: 14.375416 },
    "Mauritania": { latitude: 21.00789, longitude: -10.940835 },
    "Mauritius": { latitude: -20.348404, longitude: 57.552152 },
    "Mexico": { latitude: 23.634501, longitude: -102.552784 },
    "Moldova": { latitude: 47.411631, longitude: 28.369885 },
    "Mongolia": { latitude: 46.862496, longitude: 103.846656 },
    "Montenegro": { latitude: 42.708678, longitude: 19.37439 },
    "Morocco": { latitude: 31.791702, longitude: -7.09262 },
    "Mozambique": { latitude: -18.665695, longitude: 35.529562 },
    "Myanmar": { latitude: 21.916221, longitude: 95.955974 },
    "Namibia": { latitude: -22.95764, longitude: 18.49041 },
    "Nepal": { latitude: 28.394857, longitude: 84.124008 },
    "Netherlands": { latitude: 52.132633, longitude: 5.291266 },
    "New Zealand": { latitude: -40.900557, longitude: 174.885971 },
    "Nicaragua": { latitude: 12.865416, longitude: -85.207229 },
    "Niger": { latitude: 17.607789, longitude: 8.081666 },
    "Nigeria": { latitude: 9.081999, longitude: 8.675277 },
    "Norway": { latitude: 60.472024, longitude: 8.468946 },
    "Pakistan": { latitude: 30.375321, longitude: 69.345116 },
    "Panama": { latitude: 8.537981, longitude: -80.782127 },
    "Papua New Guinea": { latitude: -6.314993, longitude: 143.95555 },
    "Peru": { latitude: -9.189967, longitude: -75.015152 },
    "Philippines": { latitude: 12.879721, longitude: 121.774017 },
    "Poland": { latitude: 51.919438, longitude: 19.145136 },
    "Portugal": { latitude: 39.399872, longitude: -8.224454 },
    "Republic of Korea": { latitude: 35.907757, longitude: 127.766922 },
    "Romania": { latitude: 45.943161, longitude: 24.96676 },
    "Russia": { latitude: 61.52401, longitude: 105.318756 },
    "Rwanda": { latitude: -1.940278, longitude: 29.873888 },
    "Senegal": { latitude: 14.497401, longitude: -14.452362 },
    "Serbia": { latitude: 44.016521, longitude: 21.005859 },
    "Sierra Leone": { latitude: 8.460555, longitude: -11.779889 },
    "Singapore": { latitude: 1.352083, longitude: 103.819836 },
    "Slovenia": { latitude: 46.151241, longitude: 14.995463 },
    "South Africa": { latitude: -30.559482, longitude: 22.937506 },
    "South Sudan": { latitude: 6.8769919, longitude: 31.3069788 },
    "Spain": { latitude: 40.463667, longitude: -3.74922 },
    "Sri Lanka": { latitude: 7.873054, longitude: 80.771797 },
    "St. Kitts and Nevis": { latitude: 17.357822, longitude: -62.782998 },
    "St. Lucia": { latitude: 13.909444, longitude: -60.978893 },
    "St. Vincent and the Grenadines": { latitude: 12.984305, longitude: -61.287228 },
    "Sudan": { latitude: 12.862807, longitude: 30.217636 },
    "Suriname": { latitude: 3.919305, longitude: -56.027783 },
    "Sweden": { latitude: 60.128161, longitude: 18.643501 },
    "Tajikistan": { latitude: 38.861034, longitude: 71.276093 },
    "Tanzania": { latitude: -6.369028, longitude: 34.888822 },
    "Thailand": { latitude: 15.870032, longitude: 100.992541 },
    "Togo": { latitude: 0.992515, longitude: 8.652397 },
    "Trinidad and Tobago": { latitude: 10.691803, longitude: -61.222503 },
    "Tunisia": { latitude: 33.886917, longitude: 9.537499 },
    "Turkey": { latitude: 38.963745, longitude: 35.243322 },
    "Uganda": { latitude: 1.373333, longitude: 32.290275 },
    "Ukraine": { latitude: 48.379433, longitude: 31.1655799 },
    "United Arab Emirates": { latitude: 23.424076, longitude: 53.847818 },
    "United Kingdom": { latitude: 55.378051, longitude: -3.435973 },
    "United States": { latitude: 37.09024, longitude: -95.712891 },
    "Uruguay": { latitude: -32.522779, longitude: -55.765835 },
    "Uzbekistan": { latitude: 41.377491, longitude: 64.585262 },
    "Venezuela": { latitude: 6.42375, longitude: -66.58973 },
    "Vietnam": { latitude: 14.058324, longitude: 108.277199 },
    "Zambia": { latitude: -13.133897, longitude: 27.849332 },
    "Zimbabwe": { latitude: -19.015438, longitude: 29.154857 },
  },
  regions: {
    "Africa": { latitude: 15.686493, longitude: 2.283871 },
    "East Asia": { latitude: 34.656077, longitude: 129.469522 },
    "Europe/Central Asia": { latitude: 55.753934, longitude: 37.620903 },
    "LAC": { latitude: -7.541660, longitude: -73.981064 },
    "Middle East": { latitude: 29.100310, longitude: 46.560100 },
    "North America": { latitude: 48.354295, longitude: -99.997968 },
    "Oceania": { latitude: -33.925579, longitude: 151.055528 },
    "South Asia": { latitude: 22.825545, longitude: 79.475817 },
    "Southeast Asia": { latitude: 0.953324, longitude: 114.557444 },
  },
};
