import classNames from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useWindowSize, useLockBodyScroll, useWindowScroll } from 'react-use';

import About from './About';
import Header from './Header';
import IsoMap from './Map';
import Localization from './Localization';
import Table from './Table/';
import TableControls from './TableControls';
import useChinaAfricaLoanData from './useChinaAfricaLoanData';

import './App.scss';

const IntroText = props => {
  const { text, contentHeight, activate, index } = props;
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      activate(index);
    }
  }, [inView, activate, index]);
  const style = {};
  if (text.maxWidth) {
    style.maxWidth = text.maxWidth;
  }
  return (
    <div ref={ref} className="intro__text" style={{ height: contentHeight * 0.9 }}>
      {text.text && (
        <div className="textWrapper" style={style}>
          {text.text}
        </div>
      )}
    </div>
  );
};

function App() {
  const windowSize = useWindowSize();
  const [language, setLanguage] = useState(Localization.getLanguage());
  const [inIntro, setInIntro] = useState(true);
  const [aboutOpen, setAboutOpen] = useState(false);
  const headerHeight = 52;
  const contentHeight = windowSize.height - headerHeight;
  const mapSize = {
    width: windowSize.width,
    height: inIntro ? contentHeight : windowSize.width * 0.4,
  };
  const [introStep, setIntroStep] = useState(0);
  const chinaAfricaLoansData = useChinaAfricaLoanData();

  const [tableType, setTableType] = useState('sectors');
  const [tableUnit, setTableUnit] = useState('amount');

  const [selectedYears, setSelectedYears] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [showTableInstructions, setShowTableInstructions] = useState(true);

  const showPreview =
  false &&
  !window.location.origin.includes('pitchinteractive') &&
  !window.location.origin.includes('localhost');

  const introTexts = [
    {
      text: showPreview
        ? 'Coming soon...'
        : Localization.get('intro1'),
      highlightChina: true,
      showWorld: true,
    },
    {
      text: (
        <>
          {Localization.get('intro2')}
        </>
      ),
      highlightAfrica: true,
      showWorld: true,
      zoom: 2,
      maxWidth: '37em',
    },
    /*
    {
      text: (
        <>
          {' '}
          {Localization.get('intro3')}
        </>
      ),
      colorLoans: true,
      maxColor: '#b7b7b7',
      maxWidth: '30em',
    },
    */
    {
      text: (
        <>
          {Localization.get('intro4')}
        </>
      ),
      colorLoans: true,
    },
    {
      text: null,
      colorLoans: true,
      showBars: true,
    },
  ];

  const [barScaledHeight, setBarScaledHeight] = useState(0);
  let showBars = introTexts[introStep].showBars || !inIntro;

  const updateLanguage = lang => {
    Localization.setLanguage(lang);
    const language = Localization.getLanguage();
    window.history.pushState(lang, lang, `?lang=${language}${document.location.hash}`);
    setLanguage(language);
  };

  useEffect(() => {
    let t = setTimeout(() => {
      setBarScaledHeight(showBars ? 1 : 0);
    }, 300);
    return () => clearTimeout(t);
  }, [showBars, inIntro]);

  useLockBodyScroll(showPreview || aboutOpen || (showTableInstructions && !inIntro));
  useEffect(() => {
    setSelectedTypes(null);
  }, [tableType]);
  const typeAccessor = tableType === 'sectors' ? d => d.Sector : d => d['Lender Type'];

  let loansToUse = chinaAfricaLoansData;

  if (selectedCountries !== null) {
    loansToUse = loansToUse.filter(loan => selectedCountries.includes(loan.Country));
  }

  let yearFilterLoans = loansToUse;
  let typeFilterLoans = loansToUse;
  if (selectedYears !== null) {
    loansToUse = loansToUse.filter(loan => selectedYears.includes(loan.Year));
    yearFilterLoans = loansToUse;
  }

  if (selectedTypes !== null) {
    loansToUse = loansToUse.filter(loan => selectedTypes.includes(typeAccessor(loan)));
    typeFilterLoans = loansToUse;
  }
  const windowScroll = useWindowScroll();
  const pageHeight = window.innerHeight + windowScroll.y;
  const hideScrollToContinue = Math.abs(pageHeight - window.document.body.scrollHeight) < 5;

  const clickCountry = useCallback(
    country => () => {
      const countryName = country.properties.name;
      const countryHasData = chinaAfricaLoansData.some(loan => loan.Country === countryName);
      if (!countryHasData) {
        return false;
      }
      let newCountries = null;
      if (selectedCountries === null) {
        newCountries = [countryName];
      } else if (selectedCountries.includes(countryName)) {
        newCountries = selectedCountries.filter(c => c !== countryName);
        if (newCountries.length === 0) {
          newCountries = null;
        }
      } else {
        newCountries = [...selectedCountries, countryName];
        newCountries.sort();
      }
      setSelectedCountries(newCountries);
      return true;
    },
    [selectedCountries, chinaAfricaLoansData]
  );

  const clickExplore = () => {
    setBarScaledHeight(0);
    setTimeout(() => {
      setInIntro(false);
      window.scrollTo(0, 0);
    }, 500);
  };
  const backToIntro = () => {
    window.scrollTo(0, 0);
    setInIntro(true);
  };


  return (
    <div className={classNames('App', { showPreview })}>
      <Header
        setAboutOpen={setAboutOpen}
        aboutOpen={aboutOpen}
        language={language}
        updateLanguage={updateLanguage}
        showPreview={showPreview}
      />
      {showTableInstructions && !inIntro ? (
        <div className="table-instructions" onClick={() => setShowTableInstructions(false)}>
          <div className="table-instructions__text">
            {Localization.get('mapIntroOverlay')}
            <div className="close">&times;</div>
          </div>
        </div>
      ) : null}
      <div className="container">
        {inIntro ? null : (
          <TableControls
            tableType={tableType}
            tableUnit={tableUnit}
            setTableType={setTableType}
            setTableUnit={setTableUnit}
            setInIntro={backToIntro}
            resetData={() => {
              setSelectedCountries(null);
              setSelectedYears(null);
              setSelectedTypes(null);
              setTableType('sectors');
              setTableUnit('amount');
            }}
          />
        )}
        <IsoMap
          {...mapSize}
          paddingTop={headerHeight}
          inIntro={inIntro}
          regions={[]}
          introStep={introTexts[introStep]}
          chinaAfricaLoansData={loansToUse}
          clickCountry={clickCountry}
          typeAccessor={typeAccessor}
          selectedCountries={selectedCountries}
          selectedYears={selectedYears}
          type={tableType}
          setInIntro={setInIntro}
          allData={chinaAfricaLoansData}
          barScaledHeight={barScaledHeight}
        />
        {selectedCountries !== null ? (
          <div className="resetMap buButton reset" onClick={() => setSelectedCountries(null)}>
            {Localization.get('resetMap')}
          </div>
        ) : null}

        {inIntro ? (
          <>
            <div className="intro">
              {introTexts.map((text, i) => (
                <IntroText
                  activate={setIntroStep}
                  text={text}
                  contentHeight={contentHeight}
                  index={i}
                  key={i}
                />
              ))}
            </div>
            <div className="exploreButton">
              <button className="buButton" onClick={clickExplore}>
                {Localization.get('exploreData')}
              </button>
              <div className={classNames('scrollToContinue', { hideScrollToContinue })}>
                {Localization.get('scrollToContinue')}
              </div>
            </div>
          </>
        ) : (
          <Table
            width={mapSize.width}
            chinaAfricaLoansData={chinaAfricaLoansData}
            type={tableType}
            unit={tableUnit}
            selectedYears={selectedYears}
            setSelectedYears={setSelectedYears}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            selectedCountries={selectedCountries}
            loansToUse={loansToUse}
            yearFilterLoans={yearFilterLoans}
            typeFilterLoans={typeFilterLoans}
            typeAccessor={typeAccessor}
          />
        )}
      </div>
      {aboutOpen ? <About setAboutOpen={setAboutOpen} /> : null}
    </div>
  );
}

export default App;
