const colors = {
  Agriculture: '#c9841e',
  'Defense and Military': '#a5003f',
  Education: '#e95790',
  Energy: '#60a9fc',
  'Financial Sector': '#f5d32f',
  'Public Administration': '#491293',
  Health: '#17a099',
  'Information and Communication Technology': '#7dd59a',
  Industry: '#b2aea0',
  'Non-energy Mining': '#60574f',
  Other: '#738301',
  'Services and Social Protection': '#445ed4',
  'Industry and Trade/Services': '#1694c1',
  Transportation: '#3be0da',
  'Water/Sanitation/Waste': '#98dde6',
  Environment: '#0d740f',

  'Development Finance Institutions': '#ffb100',
  'Commercial Banks': '#c80713',
  Contractors: '#fd6b21',
  'Other CN Financing': '#ddcbc2',
  'Other CN Gov': '#a26400',
};

const darkColors = {
  Agriculture: '#9E4510',
  'Defense and Military': '#6b0210',
  Education: '#d51e51',
  Energy: '#2470f9',
  'Financial Sector': '#ebae28',
  'Public Administration': '#150555',
  Health: '#0a645c',
  'Information and Communication Technology': '#3eb25d',
  Industry: '#7c7764',
  'Non-energy Mining': '#241e19',
  Other: '#344406',
  'Services and Social Protection': '#1323b0',
  'Industry and Trade/Services': '#095692',
  Transportation: '#1fc4ba',
  'Water/Sanitation/Waste': '#5bbfcf',
  Environment: '#023503',

  'Development Finance Institutions': '#fd7b22',
  'Commercial Banks': '#9d040c',
  Contractors: '#fb2d1c',
  'Other CN Financing': '#bfa194',
  'Other CN Gov': '#672807',
};

export default colors;
export { darkColors };
