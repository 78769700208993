import './styles.scss';
import Localization from '../Localization';
import classNames from 'classnames';

export default function TableControls(props) {
  const { resetData, tableType, tableUnit, setTableType, setTableUnit, setInIntro } = props;
  return (
    <div className="tableControls">
      <div
        className="backToIntro"
        onClick={() => {
          resetData();
          setInIntro(false);
        }}
      >
        {Localization.get('backToIntro')}
      </div>
      <div className="tableButtons">
        <div className="toggle">
          <div className="toggleLabel">{Localization.get('type')}:</div>
          <span className="buttons">
            <button
              className={classNames({ active: tableType === 'sectors' })}
              onClick={() => setTableType('sectors')}
            >
              <span>{Localization.get('sectors')}</span>
            </button>
            <button
              className={classNames({ active: tableType === 'lenders' })}
              onClick={() => setTableType('lenders')}
            >
              <span>{Localization.get('lenders')}</span>
            </button>
          </span>
        </div>
        <div className="toggle">
          <div className="toggleLabel">{Localization.get('unit')}:</div>
          <span className="buttons">
            <button
              className={classNames({ active: tableUnit === 'amount' })}
              onClick={() => setTableUnit('amount')}
            >
              <span>{Localization.get('amountCommitted')}</span>
            </button>
            <button
              className={classNames({ active: tableUnit === 'count' })}
              onClick={() => setTableUnit('count')}
            >
              <span>{Localization.get('numOfLoans')}</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
