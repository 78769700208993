export const enObj = {
  "About / Data Download": "About / Data Download",
  "Chinese Loans to Africa Database": "Chinese Loans to Africa Database",
  language: "Language",
  title: "Chinese Loans to Africa Database",
  intro1:
    "The Chinese Loans to Africa (CLA) Database is an interactive data project tracking loan commitments from Chinese development finance institutions, commercial banks, government entities and companies to African governments, state-owned enterprises and regional multilateral institutions.",
  intro2:
    "Starting in 2007, researchers have collected, cleaned and analyzed publicly available data to create a database on Chinese lending to Africa. The data sources include official government documents, contractor websites, fieldwork, interviews and media sources.",
  intro3:
    "As of March 29, 2021, the CLA Database is managed by the Boston University Global Development Policy Center.",
  intro4:
    "The CLA Database estimates that from 2000-2023, 41 Chinese lenders signed 1,306 loan commitments amounting to $182.28 billion with 49 African governments and seven regional institutions. CLA Database loan amounts are not equivalent to African government debt, as the database tracks commitments, and not disbursement, repayments or defaults.",
  exploreData: "Explore the data",
  scrollToContinue: "or scroll to continue",
  about: "About",
  dataDownload: "Data Download",
  africa: "Africa",
  amount: "Amount",
  noOfLoans: "No. of Loans",
  numOfLoans: "Number of Loans",
  regionalLoans: "Regional Loans",
  regionalLoansInfo:
    "Regional loans are loans to African regional institutions such as the African Export Import Bank (Afreximbank), African Finance Corporation (AFC), Development Bank of Central African States (BDEAC), Banque Ouest Africaine de Developpement (BOAD),  Trade and Development Bank (TDB), Organisation pour la mise en valeur du fleuve Sénégal (OMVS) and Arab International Bank (AIB).",
  committedAmountPerCountry: "Committed amount ($) per country",
  type: "Type",
  sectors: "Sectors",
  lenders: "Lenders",
  lender: "Lender",
  unit: "Unit",
  country: "Country",
  project: "Project",
  years: "Years",
  year: "Year",
  clickExploreText: (value) => `Click to explore the data of "${value}"`,
  selectOneOrMoreSectorsToUpdate:
    "Select one or more sectors to update the map display",
  selectOneOrMoreYearsToUpdate:
    "Select one or more years to update the map display",
  backToIntro: "Back To Intro",
  amountCommitted: "Amount Committed",
  dollarCommitted: "$ Committed",
  dollarAllocation: "$ Allocation",
  mapIntroOverlay:
    "Select one or more multiple countries on the map to explore projects and loans by year, sector, and lender type.",
  energy: "Energy",
  transport: "Transportation",
  ict: "Information and Communications Technology",
  industryTradeServices: "Industry and Trade/Services",
  other: "Other",
  waterSanitationWaste: "Water/Sanitation/Waste",
  publicAdmin: "Public Administration",
  defenseMilitary: "Defense and Military",
  agriculture: "Agriculture",
  education: "Education",
  health: "Health",
  environment: "Environment",
  nonEnergyMining: "Non-energy Mining",
  socialProtection: "Social Protection",
  expandAll: "Expand All",
  expandAllLists: "Expand All Lists",
  collapseAll: "Collapse All",
  collapseAllLists: "Collapse All Lists",
  clickToExpandCollapseGroup: "Click to expand/collapse group",
  clickToExpandCollapseCountry: "Click to expand/collapse country",
  resetMap: "Reset Map",
  noProjectsInCountry: (value) => `No projects in ${value}`,
  multipleCountries: "Multiple Countries",
  lists: "Lists",
  resetTypes: "Reset Types",
  resetYears: "Reset Years",
  clickToSelectAndUpdateMapDisplay: (value) =>
    `Click to select "${value}" and update the map display`,
  policyBanks: "Policy Banks",
  chexim: "Export-Import Bank of China",
  cdb: "China Development Bank",
  otherCNGov: "Other CN Gov",
  "CN Gov": "CN Gov",
  cidca: "China International Development Corporation Agency",
  pbc: <>People's Bank of China'</>,
  cnGov: "Unkown Chinese government entity",
  commericalBanks: "Commercial Banks",
  icbc: "Industrial and Commercial Bank of China",
  ccb: "China Construction Bank",
  abc: "Agricultural Bank of China",
  citic: "China International Trust Investment Corporation",
  boc: "Bank of China",
  contractors: "Contractors",
  parentCompany: "Parent Company or Subsidiary Company",
  otherCN: "Other CN",
  mixedCN:
    "Syndicated loans where the participants include both Chines government entities, Chinese commercial banks, and/or Chinese contractors. All participants must be Chinese.",
  ambiguousLenders:
    "Ambiguous lenders such as China International Fund or Africa Growing Together Fund.",
  amountOfAfrica: "$ Amount of Africa",
  noloa: "No. of Loans of Africa",
  loan: "loan",
  loans: "loans",
  acronym: "Acronym",
  ucge: "Unknown Chinese government entity",
  pcosc: "Parent Company or Subsidary Company",
  companyName: "Company Name",
  leanAmount: " ",
};
