import { useEffect } from "react";
import Localization from "../Localization";
import "./styles.scss";

export default function About(props) {
  const { setAboutOpen } = props;

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "8473896",
        formId: "1792b6af-228a-4655-ad5c-6c2cb5006172",
        target: "#hubspot-form",
      });
    } else {
      console.warn("Error loading hubspot form");
    }
  }, []);

  if (Localization.getLanguage() === "en") {
    return (
      <div className="AboutModal">
        <div className="content">
          <div className="close" onClick={() => setAboutOpen(false)}>
            &times;
          </div>

          <div className="copy">
            <h2 className="aboutHead">About the data</h2>
            <p>
              The Chinese Loans to Africa (CLA) Database is an interactive data
              project tracking loan commitments from Chinese development finance
              institutions, commercial banks, government entities and companies
              to African governments, state-owned enterprises and regional
              institutions.
            </p>
            <p>
              The CLA Database was started by the China Africa Research
              Initiative at Johns Hopkins University's School of Advanced
              International Studies (SAIS-CARI). As of March 29, 2021, the CLA
              Database is managed by the Boston University Global Development
              Policy Center (GDP Center).
            </p>
            <p>
              The GDP Center is a University-wide center in partnership with the
              Frederick S. Pardee School of Global Studies at Boston University.
              The GDP Center's mission is to advance policy-oriented research
              for financial stability, human well-being and environmental
              sustainability.
            </p>
            <p>
              Starting in 2007, researchers have collected, cleaned and analyzed
              publicly available data to create a database on Chinese lending to
              Africa. The data sources include official government documents,
              contractor websites, fieldwork, interviews and media sources.
            </p>
            <p>
              The CLA Database estimates that from 2000-2023, 41 Chinese lenders
              signed 1,306 loan commitments amounting to $182.28 billion with 49
              African governments and seven regional institutions. CLA Database
              loan amounts are not equivalent to African government debt, as the
              database tracks commitments, and not disbursement, repayments or
              defaults.
            </p>
            <h3 className="aboutHead">Contact:</h3>
            <p>
              For technical inquiries or suggestions regarding this database,
              please contact Diego Morro,{" "}
              <a href="mailto:dm3760@bu.edu">dm3760@bu.edu</a>.
            </p>
            <p>
              For media inquiries, please contact Maureen Heydt,{" "}
              <a href="mailto:mheydt@bu.edu">mheydt@bu.edu</a>.
            </p>
            <p>Database last updated: August, 2024.</p>
            <h3 className="aboutHead">Suggested Citation:</h3>
            <p>
              <strong>
                Boston University Global Development Policy Center. 2024.
                Chinese Loans to Africa Database. Retrieved from
                http://bu.edu/gdp/chinese-loans-to-africa-database.
              </strong>
            </p>
            <h3 className="aboutHead">Resources:</h3>
            <p>For research and policy commentary, please refer to:</p>
            <ul>
              <li>
                <a
                  href="https://www.bu.edu/gdp/2024/08/21/relative-risk-and-the-rate-of-return-chinese-loans-to-africa-database-2000-2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2024 Policy Brief
                </a>
              </li>
              <li>
                <a
                  href="https://www.bu.edu/gdp/2023/08/18/a-new-state-of-lending-chinese-loans-to-africa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2023 Policy Brief
                </a>
              </li>
              <li>
                <a
                  href="https://www.bu.edu/gdp/2022/04/22/chinese-loans-to-africa-during-the-covid-19-pandemic/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2022 Policy Brief
                </a>
              </li>
            </ul>
            <p>For information on the database methodology, refer to:</p>
            <ul>
              <li>
                For the years 2020 and onwards, the{" "}
                <a
                  href="https://www.bu.edu/gdp/2021/03/23/gdp-center-database-methodology-guidebook/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GDP Center Database Methodology Guidebook
                </a>
              </li>
              <li>
                or the years 2000-2019, the{" "}
                <a
                  href="https://www.bu.edu/gdp/2021/03/26/twenty-years-of-data-on-chinas-africa-lending/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SAIS-CARI Methodology Guidebook
                </a>
              </li>
            </ul>
            <h2 className="aboutHead">Acknowledgements:</h2>
            <p>
              Since 2022, the CLA Database has been built with generous support
              from the Carnegie Corporation, the William and Flora Hewlett
              Foundation, the Rockefeller Brothers Fund and the Charles Stewart
              Mott Foundation.
            </p>
            <p>
              The 2000-2019 CLA Database was built thanks to a grant from
              Carnegie Corporation of New York. Carnegie Corporation of New York
              is a philanthropic foundation created by Andrew Carnegie in 1911
              to do “real and permanent good in this world.”
            </p>
            <p>
              The GDP Center would like to gratefully acknowledge the vision and
              diligence of SAIS-CARI Director Deborah Bräutigam for creating
              this database and conducting this research. The GDP Center looks
              forward to carrying on the legacy of providing timely and accurate
              information on Chinese loans in Africa.
            </p>
            <p>
              The CLA Database, from 2022 onward, benefitted from the work of
              research managers and research assistants, including Kevin Acker,
              Jyhjong Hwang, Xinyue Ma, Oyintarelado Moses, Lucas Engel, Violet
              Barton, Victoria Bien-Aimé, Siyu Xie, Chenyu Wei, Samuel W. Croy,
              Yulian Fan, Yumao Gao, Zhenkun Huang, Abena Oduro, Reed Piercy,
              Sobia Shadbar, Keyi Tang, Ava Wang, Yinxuan Wang, Jiaming Liang,
              Maame Ampem, Hao Lyu, Diego Morro, Tianxiang Chen, Richard
              Pilleul, Petter Kolaas, Nicolo Capirone, Li-Yuan Lin, Boyuan Chen,
              Keyi Liu and Qingyang Fang.The GDP Center also acknowledges the
              work of the many SAIS-CARI research managers and research
              assistants in building the CLA Database since 2007.
            </p>
            <p>
              The Chinese Loans to Africa Database was created by{" "}
              <a
                href="https://pitchinteractive.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pitch Interactive
              </a>
              .
            </p>
            <h2 className="aboutHead">Data Download:</h2>
            <p>
              To download the data, please fill out the form submission below:
              <br />
              <br />
            </p>
            <div id="hubspot-form" />
          </div>
        </div>
      </div>
    );
  } else {
    //Chinese About Copy here
    return (
      <div className="AboutModal">
        <div className="content">
          <div className="close" onClick={() => setAboutOpen(false)}>
            &times;
          </div>

          <div className="copy">
            <h2 className="aboutHead">关于数据</h2>
            <p>
              中国对非洲贷款（CLA）数据库是一个交互数据项目，追踪中国的开发性金融机构
              (DFIs)、商业银行、政府实体和公司向非洲政府、国有企业和区域性机构提供的贷款承诺。
            </p>
            <p>
              中国对非洲贷款数据库由约翰霍普金斯大学高级国际研究学院中非研究倡议（SAIS-CARI）发起。截至2021年3月29日，中国对非洲贷款数据库由波士顿大学全球发展政策研究中心（GDP
              中心）运营。
            </p>
            <p>
              波士顿大学全球发展政策研究中心是波士顿大学的校级研究机构，与佛雷德里克·帕迪（Frederick
              S.
              Pardee）全球研究学院紧密合作。波士顿大学全球发展政策研究中心致力推动政策导向型研究，以促进金融稳定、人类福祉和环境可持续性。
            </p>
            <p>
              自2007以来，研究人员收集、整理并分析了相关公开数据，创建了中国对非洲贷款数据库。数据来源包括政府官方文件、承包商网站、实地调查、访谈和媒体来源。
            </p>
            <p>
              中国对非洲贷款数据库估算在2000到2023年间，41家中国贷款机构向49个非洲政府和7家区域性机构提供了总计1306笔贷款承诺，总额达1822.8亿美元。该数据库中的贷款金额并不等同于非洲政府债务，因为数据库跟踪的是贷款承诺，而不是实际支付、偿还或违约情况。
            </p>
            <h3 className="aboutHead">联系我们：</h3>
            <p>
              有关数据库的技术咨询或建议，请联系Diego Morro，邮箱：{" "}
              <a href="mailto:dm3760@bu.edu">dm3760@bu.edu</a>.
            </p>
            <p>
              有关媒体咨询，请联系Maureen Heydt，邮箱：{" "}
              <a href="mailto:mheydt@bu.edu">mheydt@bu.edu</a>.
            </p>
            <p>数据库于2024年8月更新。</p>

            <h3 className="aboutHead">建议引用：</h3>
            <p>
              <strong>
                Boston University Global Development Policy Center. 2024.
                Chinese Loans to Africa Database. Retrieved from
                http://bu.edu/gdp/chinese-loans-to-africa-database.
              </strong>
            </p>
            <h3 className="aboutHead">资源：</h3>
            <p>有关研究和政策评论，请参阅:</p>
            <ul>
              <li>
                <a
                  href="https://www.bu.edu/gdp/2024/08/21/relative-risk-and-the-rate-of-return-chinese-loans-to-africa-database-2000-2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2024年政策简报
                </a>
              </li>
              <li>
                <a
                  href="https://www.bu.edu/gdp-cn/2023/09/27/%e7%a0%94%e7%a9%b6%e9%80%9f%e9%80%92%ef%bd%9c%e5%80%9f%e8%b4%b7%e6%96%b0%e8%b6%8b%e5%8a%bf%ef%bc%9a%e4%b8%ad%e5%9b%bd%e5%af%b9%e9%9d%9e%e6%b4%b2%e8%b4%b7%e6%ac%be%e6%83%85%e5%86%b5/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2023年政策简报
                </a>
              </li>
              <li>
                <a
                  href="https://www.bu.edu/gdp-cn/2022/05/09/%e7%a0%94%e7%a9%b6%e9%80%9f%e9%80%92%ef%bd%9c%e5%85%a8%e7%90%83%e6%96%b0%e5%86%a0%e7%96%ab%e6%83%85%e6%9c%9f%e9%97%b4%e4%b8%ad%e5%9b%bd%e5%af%b9%e9%9d%9e%e6%b4%b2%e8%b4%b7%e6%ac%be%e6%83%85%e5%86%b5/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2022年政策简报
                </a>
              </li>
            </ul>
            <p>有关数据库使用方法的信息，请参阅:</p>
            <ul>
              <li>
                2020年及以后，见{" "}
                <a
                  href="https://www.bu.edu/gdp/2021/03/23/gdp-center-database-methodology-guidebook/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  《GDP数据库使用方法指南》
                </a>
              </li>
              <li>
                2000年至2019年，见{" "}
                <a
                  href="https://www.bu.edu/gdp/2021/03/26/twenty-years-of-data-on-chinas-africa-lending/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  《SAIS-CARI使用方法指南》
                </a>
              </li>
            </ul>
            <h2 className="aboutHead">致谢：</h2>
            <p>
              从2022年至今，由于纽约卡内基基金会、威廉与佛洛拉·休利特基金会、洛克菲勒兄弟基金会和查尔斯·斯图尔特·莫特基金会的慷慨支持，让中国对非洲贷款数据库得以呈现。
            </p>
            <p>
              2000年至2019年的中国对非洲贷款数据库得以建立，要感谢来自纽约卡内基基金会的资助。纽约卡内基基金会是由Andrew
              Carnegie于1911年创建的慈善基金会，旨在“做世界上真正的、永久的善事”。
            </p>
            <p>
              波士顿大学全球发展政策研究中心衷心感谢约翰霍普金斯大学高级国际研究学院中非研究倡议主任Deborah
              Bräutigam的远见和付出，因为她创建了该数据库并开展了此项研究。GDP中心期待延续传统，及时、准确地提供有关中国对非洲贷款的信息。。
            </p>
            <p>
              自2022年以来，中国对非洲贷款数据库得益于研究经理和研究助理的工作，其中包括Kevin
              Acker、Jyhjong Hwang、Xinyue Ma、Oyintarelado Moses、Lucas
              Engel、Violet Barton、Victoria Bien-Aimé、Siyu Xie、Chenyu
              Wei、Samuel W. Croy、Yulian Fan、Yumao Gao、Zhenkun Huang、Abena
              Oduro、Reed Piercy、Sobia Shadbar、Keyi Tang、Ava Wang、Yinxuan
              Wang、Jiaming Liang、Maame Ampem、Hao Lyu、Diego Morro、Tianxiang
              Chen、Richard Pilleul、Petter Kolaas、Nicolo Capirone、Li-Yuan
              Lin、Boyuan Chen、Keyi Liu和Qingyang
              Fang。GDP中心还感谢自2007年以来，在构建中国对非洲贷款数据库上做出贡献的许多来自SAIS-CARI的研究经理和研究助理。
            </p>
            <p>
              中国对非洲贷款（CLA）数据库由
              <a
                href="https://pitchinteractive.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pitch Interactive
              </a>
              建立。
            </p>
            <h2 className="aboutHead">数据下载:</h2>
            <p>
              如需下载数据，请填写下面的表格并提交：
              <br />
              <br />
            </p>
            <div id="hubspot-form" />
          </div>
        </div>
      </div>
    );
  }
}
