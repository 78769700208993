export const sectors = {
  Health: '医疗业',
  'Industry and Trade/Services': '工业、贸易和服务业',
  Education: '教育业',
  Agriculture: '农业',
  Energy: '能源业',
  'Water/Sanitation/Waste': '水利/卫生/废物处理',
  Transportation: '交通业',
  'Information and Communication Technology': '信息和通信技术业',
  'Public Administration': '公共管理业',
  Other: '其他',
  'Non-energy Mining': '非能源采矿业',
  'Services and Social Protection': '服务和社会保障业',
  Environment: '环境',
  'Defense and Military': '国防和军事',
  'Financial Sector': '金融业',
};
